import React, { useEffect, useRef, useState , forwardRef, useImperativeHandle } from 'react';
import { Button, Col, Container, Form, InputGroup, NavDropdown, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { createTheme } from 'react-data-table-component';
import { useLocation, useNavigate } from 'react-router-dom';
import 'reactjs-popup/dist/index.css';
import EmptyDataTableFilterSearch from '../../../../../Assets/Images/Illustrations/EmptyDataTableFilterSearch.svg';
import RaiseTicketIllustration from '../../../../../Assets/Images/Illustrations/raiseticketillustration.svg';
import AccessDenied from '../../../../../GlobalComponents/EmptyStates/AccessDenied';
import Toast from '../../../../../GlobalComponents/Toast';
import { api } from '../../../../../Utils/API';
import { CSP_SUBSCRIPTION_DETAILS, DASHBOARD_SUBSCRIPTIONS_PLAN_LIST, DASHBOARD_SUBS_RENEW_DOWNLOAD, GET_CUSTOMERS_LIST, GET_SERVICES, GOOGLE_WORKSPACE_SUBSCRIPTION_DETAILS, RENEWALSETTINGS_DURATION, RENEWALSETTINGS_TABLE, RENEWAL_SETTINGS_SUBS_DOWNLOAD } from '../../../../../Utils/GlobalConstants';
import LazyTable from './LazyTable';
import GWSRenewalSettingsPage from './RenewalSettings/GoogleWorkspace';
import CSPRenewalSettingsPage from './RenewalSettings/MicrosoftCSP';
import styles from './renewalSettingsTab.module.scss';
import { BACKGROUND_COLOR_SECONDARY, REACT_DATA_TABLE_COLOR_THEME, TEXT_SECONDARY, TOAST_ERROR_ICON_COLOR, TOAST_SUCCESS_ICON_COLOR } from '../../../../../Utils/ColorsConfiguration';
import { GenericEmptyMatchingSubscription, GenericEmptySubscriptionError, GenericServerError } from '../../../../../Utils/StaticMessages/GenericErrorMessages';
import { CustomerFetchingErrorToast, DownloadReportErrorToast, GenericDownloadEmptyToast, ServiceFetchingErrorToast } from '../../../../../Utils/StaticMessages/ToastMessages';

const RenewalSettingsTab = forwardRef(({ tabName, Logout, onStateChange }, ref) => {

    const location = useLocation();
    const date = new Date();                                                    // for js date time function
    const navigate = useNavigate(); 
    const searchParams = new URLSearchParams(location.search);
    const [searchIconFlag, setSearchIconFlag] = useState(true);                // to open and close search box
    const [searchValueTable, setSearchValueTable] = useState(searchParams.get('searchValueTable') || null);         // storing searching value in the search input box
    const searchInputRef = useRef();
    const [status, setStatus] = useState(null);                                  // getTableData() Status 
    const [fromDate, setFromDate] = useState(null);                             // getTableData() summary from date
    const [toDate, setToDate] = useState(null);                                 // getTableData() summary to date
    const [pageNo, setPageNo] = useState(1);                                    // table active page number
    const [planName, setPlanName] = useState(null);                                    // table active page number
    const [autoRenew, setAutoRenew] = useState(null);                                    // table active page number
    const [sortCol, setSortCol] = useState("remainingDayNumber");                                    // table active page number
    const [sortDir, setSortDir] = useState("ASC");                                    // table active page number
    const [isDescStartDate, setIsDescStartDate] = useState(null);               // sort state check for summary number column
    const [isDescEndDate, setIsDescEndDate] = useState(null);               // sort state check for summary number column

    const [pageSize, setPageSize] = useState(15);                                // table active page size
    const [totalRows, setTotalRows] = useState(0);                              // table total count of data from api 
    const [columns, setColumns] = useState([]);                                 // columns used to display column headers in the react data table component
    const [table, setTable] = useState([]);                                     // data state used to display data in react data table component
    let ColumnName = ["Customer", "Service", 
    "Plan", "Commitment Term (Billing Frequency)", "Quantity", "Start Date", "End Date", "Auto Renew",
    "Days Remaining", "Renewal Settings"];
    let ColumnNameNoAction = ["Customer", "Service",
    "Plan", "Commitment Term (Billing Frequency)", "Quantity", "Start Date", "End Date", "Auto Renew",
    "Days Remaining"];
    const [initialLoading, setInitialLoading] = useState(true);                 // loader for table
    const [emptyCustomerFilters, setEmptyCustomerFilters] = useState(false);    // empty filter response check state in table during api call to load data
    const [emptyCustomerSearch, setEmptyCustomerSearch] = useState(false);      // empty search response check state in table during api call to load data
    const [serverErrorCustomer, setServerErrorCustomer] = useState(false);      // server error check state in table during api call to load data
    const [emptyCustomer, setEmptyCustomer] = useState(false);                  // empty table check state in table during api call to load data
    const [dateLastUsedDate, setDateLastUsedDate] = useState("default");        // date dropdown saved data after applying filters
    const [statusLastUsed, setStatusLastUsed] = useState("default");            // status dropdown saved data after applying filters  
    const [autoRenewData, setAutoRenewData] = useState(null);                   // for displaying total number in center of piechart
    const [planData, setPlanData] = useState(null);                             // for displaying total number in center of piechart
    const [customerData, setCustomerData] = useState(null);                     // data in customer dropdown
    const [customerId, setCustomerId] = useState(null);                         // data in customer id dropdown

    // if custom was selected in date in filter box
    // Calculate tomorrow's date
    const today = new Date();
    today.setDate(today.getDate() + 1);
    const tomorrow = today.toISOString().slice(0, 10);

    // Initialize states with tomorrow's date
    const [dateCustomFromLast, setDateCustomFromLast] = useState(tomorrow);
    const [dateCustomToLast, setDateCustomToLast] = useState(tomorrow);

    // Use State for Toasts
    const [toastSuccess, setToastSuccess] = useState(false);                    // toast message displaying success message
    const [toastError, setToastError] = useState(false);                        // toast message displaying error message
    const [toastMessage, setToastMessage] = useState("");                       // toast message usestate

    //lazy loading
    const [scrollToDivTop, setScrollToDivTop] = useState(false);                        // usestate to scroll to top when applied filter or search when already scrolled
    const [infinityLoading, setInfinityLoading] = useState(false);                      // loading state while table loads for lazy loading
    const [tableFilterApplied, setTableFilterApplied] = useState(false);                // table filter is apllied or not

    const [isDisabledRenewalSettings, setIsRenewalSettings] = useState(localStorage.getItem("disabled_array") && localStorage.getItem("disabled_array").includes("Renewals") ? true : false);
    const [isReadRenewalSettings, setIsReadRenewalSettings] = useState(localStorage.getItem("read_array") && localStorage.getItem("read_array").includes("Renewals") ? true : false);

    const [selectedItem2, setSelectedItem2] = useState('Total'); // State for column 2 dropdown (type)
    const [selectedItem3, setSelectedItem3] = useState('Microsoft CSP'); // State for column 3 dropdwon (service)
    // const [integrationCode, setIntegrationCode] = useState("microsoftsaas"); 
    const [integrationCode, setIntegrationCode] = useState(""); 
    // const [serviceId, setServiceId] = useState("18");  
    const [serviceId, setServiceId] = useState(""); 
    const [durationOptions, setDurationOptions] = useState([]);
    const [selectedDuration, setSelectedDuration] = useState(''); // Default value
    const [serviceOptions, setServiceOptions] = useState([]);
    const [selectedService, setSelectedService] = useState('Microsoft CSP'); // Default value
    const [selectedCustomer, setSelectedCustomer] = useState('All'); // For customer dropdown
    const [customerOptions, setCustomerOptions] = useState([]); // For storing customer options
    const [customerSearchValue, setCustomerSearchValue] = useState('');
    const [customerOptionsFiltered, setCustomerOptionsFiltered] = useState([]);
    const customerSearchBoxRef = useRef();
    const isFirstSortEffect = useRef(true);
    const isFirstServiceEffect = useRef(true);
    const [isDescAutoRenew, setIsDescAutoRenew] = useState(null);
    const [isDescDaysRemaining, setIsDescDaysRemaining] = useState(null);
    const [isSubscriptionDetailsPopupOpen, setIsSubscriptionDetailsPopupOpen] = useState(false);                        // Subscription Details Popup Box
    const [subscriptionDetailIsLoadingFlag, setSubscriptionDetailIsLoadingFlag] = useState(false)                       // Subscription is Loading Popup
    const [subscriptionDetailsPlanName, setSubscriptionDetailsPlanName] = useState("Loading...");
    const [subscriptionDetails, setSubscriptionDetails] = useState({
    planName: '',
    quantity: '',
    billingTerm: '',
    startDate: '',
    endDate: '',
    vendorSubscriptionId: ''
    });
    const [popupIsReady, setPopupIsReady] = useState(false);
    const [tooltipVisible, setTooltipVisible] = useState(false);
    const tableWrapperRef = useRef(null);
    const [showCustomerDropdown, setShowCustomerDropdown] = useState(false);
    const [renewalSettingsActionPopupModalOpen, setRenewalSettingsActionActionPopupModalOpen] = useState(false);
    const [renewalSettingsActionPopupModalOpenCSP, setRenewalSettingsActionPopupModalOpenCSP] = useState(false);
    const [actionsSubscriptionId, setActionsSubscriptionId] = useState(null);
    const [actionsPlanName, setActionsPlanName] = useState(null);
    const [subscriptionTableAPIData, setSubscriptionTableAPIData] = useState(null);
    let ColumnNameSubscriptionTable = ["Billing Term", "Quantity", "Auto Renew", "RRP (Inc GST)", "Price (Ex GST)"];   // table headers used to make object of react data table component 
    const [columnsSubscriptionTable, setColumnsSubscriptionTable] = useState([]);                                       // columns used to display column headers in the react data table component
    const [tableSubscriptionTable, setTableSubscriptionTable] = useState([]);
    const [isNcePlan, setIsNcePlan] = useState(null);
    const [actionsSelectedRow, setActionsSelectedRow] = useState(null);                                // to check whether it is nce plan or legacy plan 
    const lastTooltipTime = useRef(0);
    const tooltipTimeout = useRef(null);   
    const [refershFlagAction, setRefershFlagAction] = useState(false); 
    const currentServiceRef = useRef(selectedService);


    useImperativeHandle(ref, () => ({
        getValues: () => ({
            integrationCode,
            serviceId,
            customerId,
            pageNo,
            pageSize,
            searchValueTable,
            sortCol,
            sortDir
        })
    }));


    // Enhanced scroll handling
    useEffect(() => {
    const handleScroll = () => {
        // Force tooltip to close immediately on any scroll
        const tooltips = document.querySelectorAll('.tooltip');
        tooltips.forEach(tooltip => {
            tooltip.style.display = 'none';
        });
        
        // Also update our tooltip state if needed
        if (tooltipVisible) {
            setTooltipVisible(false);
        }
    };
    // Use both capture and bubble phase for immediate response
    const scrollOptions = { passive: true, capture: true };
    // Get all scroll containers and filter out nulls
    const scrollContainers = [
        window,
        document.querySelector('.rdt_TableWrapper'),
        document.querySelector('.table-responsive'),
        tableWrapperRef.current,
        document.querySelector('.main-content')
    ].filter(Boolean);
    // Add scroll listeners
    scrollContainers.forEach(container => {
        container.addEventListener('scroll', handleScroll, scrollOptions);
    });

    // Clean up function
    return () => {
        scrollContainers.forEach(container => {
            container.removeEventListener('scroll', handleScroll, scrollOptions);
        });
    };
    }, [tooltipVisible]); // Only re-run if tooltipVisible changes

    useEffect(() => {
        if (onStateChange) {
            onStateChange({
                selectedItem2,
                integrationCode,
                serviceId
            });
        }
    }, [selectedItem2, integrationCode, serviceId]);

    async function GetDurationOptions() {
        await api.get(`${RENEWALSETTINGS_DURATION}`, config)
        .then(resp => {
            // Filter out any "All" option from API response since we'll add it manually
            const filteredOptions = resp.data.filter(option => option.id !== 'All');
            
            // Create final options array with "All" at the beginning
            const allOptions = [
                { id: 'All', name: 'All' },
                ...filteredOptions
            ];
            
            setDurationOptions(allOptions);
            setSelectedDuration('All'); // Set default selection to 'All'
        })
        .catch(error => {
            if (error?.status == 401 || error?.response?.status == 401) {
                Logout();
            }
        });
    }

    const handleCustomerSearch = (e) => {
    const searchValue = e.target.value;
    setSearchValueTable(searchValue);

    // If search value is empty
    if (searchValue === '') {
        setPageNo(1);
        setTable([]);
        setScrollToDivTop(true);
        setShowCustomerDropdown(false);
        setSelectedCustomer('All');
        setCustomerId(null);
        
        const currentDuration = selectedDuration.split(" ")[0] || 'All';
        
        // Trigger API call with reset parameters
        GetTableData(
            1,
            pageSize,
            "",
            status,
            planName,
            autoRenew,
            fromDate,
            toDate,
            sortCol,
            sortDir,
            serviceId,
            null,
            true,
            false,
            currentDuration
        );
        return;
    }
    
    if (searchValue.length > 2) {
        const updatedOptions = customerOptions.filter(option => {
            if (option.name) {
                return option.name.toLowerCase().includes(searchValue.toLowerCase());
            }
            return false;
        });

        if (updatedOptions.length > 0) {
            setCustomerOptionsFiltered(updatedOptions);
        } else {
            setCustomerOptionsFiltered([{ "id": "no-data", "name": "No data available" }]);
        }
        setShowCustomerDropdown(true);
    } else {
        setCustomerOptionsFiltered(customerOptions);
        setShowCustomerDropdown(false);
    }
    };

    useEffect(() => {
    const handleClickOutside = (event) => {
        // Get the dropdown element
        const dropdownElement = document.querySelector('.dynamic-options-block');
        
        // Check if the click is outside both the search input and the dropdown
        if (searchInputRef.current && 
            !searchInputRef.current.contains(event.target) && 
            (!dropdownElement || !dropdownElement.contains(event.target))) {

            // If we're actively searching (dropdown is visible) and click outside
            if (showCustomerDropdown) {
                setShowCustomerDropdown(false);
                setSearchValueTable(""); // Clear search text
                setCustomerId(null);
                setSelectedCustomer('All');

                 const currentDuration = selectedDuration.split(" ")[0] || 'All';

                GetTableData(
                    1,
                    pageSize,
                    "",
                    status,
                    planName,
                    autoRenew,
                    fromDate,
                    toDate,
                    sortCol,
                    sortDir,
                    serviceId,
                    null,
                    true,
                    false,
                    currentDuration // Pass the current selected duration
                );
            }
        }
    };

    // Separate scroll handler for the dropdown
    const handleScroll = () => {
        if (searchInputRef.current) {
            const rect = searchInputRef.current.getBoundingClientRect();
            const isVisible = rect.top >= 0 && rect.bottom <= window.innerHeight;
            
            // Only show dropdown if search has 3+ characters and input is visible
            if (isVisible && searchValueTable?.length > 2) {
                setShowCustomerDropdown(true);
            }
        }
    };

    // Add scroll event listeners to specific containers
    const scrollContainers = [
        document.querySelector('.dynamic-options-block'),
        document.querySelector('.table-responsive'),
        document.querySelector('.main-content')
    ].filter(Boolean);

    scrollContainers.forEach(container => {
        container.addEventListener('scroll', handleScroll, { passive: true });
    });

    // Add click outside listener
    document.addEventListener('mousedown', handleClickOutside);

    // Cleanup
    return () => {
        scrollContainers.forEach(container => {
            container.removeEventListener('scroll', handleScroll);
        });
        document.removeEventListener('mousedown', handleClickOutside);
    };
    }, [searchValueTable]);

    async function GetAllCustomers() {
        try {
            const resp = await api.get(GET_CUSTOMERS_LIST, config);
            
            const formattedOptions = [
                { id: 'All', name: 'All Customers' },
                ...resp.data.map(customer => ({
                    id: customer.id,
                    name: customer.name
                }))
            ];
            
            setCustomerData(resp.data);
            setCustomerOptions(formattedOptions);
            setCustomerOptionsFiltered(formattedOptions);
            
            if (!selectedCustomer) {
                setSelectedCustomer('All');
            }

        } catch (error) {
            console.error('Error fetching customers:', error);
            if (error?.status === 401 || error?.response?.status === 401) {
                Logout();
                return;
            }

            // Show error toast
            setToastMessage(CustomerFetchingErrorToast);
            setToastError(true);
            setTimeout(() => {
                setToastError(false);
            }, 2000);
        }
    }

    // Header Authorization for General API's
    const config = {
        headers: {
            "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
        },
    };

    // Header Authorization for Download CSV API having response type as blob
    const configBlob = {
        headers: {
            "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
        },
        responseType: 'blob',
    };

    // Download File using Js blobs
    const downloadFile = ({ data, fileName, fileType }) => {
        const blob = new Blob([data], { type: fileType })
        const a = document.createElement('a')
        a.download = fileName
        if (fileType === "application/csv") {
            a.href = window.URL.createObjectURL(data)
        }
        else {
            a.href = window.URL.createObjectURL(blob)
        }
        const clickEvt = new MouseEvent('click', {
            view: window,
            bubbles: true,
            cancelable: true,
        })
        a.dispatchEvent(clickEvt)
        a.remove()
    }

    // Download CSV File 
    async function DownloadAction(url, name, fileType) {
        let configuration;
        if (fileType === "csv") {
            configuration = configBlob;
        }
        else {
            configuration = config;
        }
        await api.get(`${url}?serviceId=${serviceId}&type=${selectedItem2}&integrationCode=${integrationCode}`, configuration)
            .then(resp => {
                // handle success
                if (resp.status === 204) {
                    setToastMessage(GenericDownloadEmptyToast);
                    setToastError(true);
                    setTimeout(() => {
                        setToastError(false);
                    }, 2000);
                }
                else {
                    downloadFile({
                        data: (resp.data),
                        fileName: name,
                        fileType: `application/${fileType}`,
                    });
                }
            })
            .catch(error => {
                if (error?.status == 401 || error?.response?.status == 401) {
                    Logout();
                }
                else {
                }
            });
    };

    //for actions core data 
    async function GetActionsData(subscriptionId,apiEndPoint) {
                (subscriptionId === undefined || subscriptionId === null) ? subscriptionId = "" : subscriptionId = subscriptionId;
                await api.get(`${apiEndPoint}${subscriptionId}`, config)
                    .then(resp => {
                        if (resp.status == 204) {
    
                        }
                        // handle success
                        else {
                            let f = [];
                            setSubscriptionTableAPIData(resp.data);
                            if(resp.data.isNcePlan){
                                if (resp.data.isNcePlan === true) {
                                    setIsNcePlan(true);
                                } else {
                                    setIsNcePlan(false);
                                }
                            }
                            f.push
                                (
                                    {
                                        "Billing Term": resp.data.billingTerm,
                                        "Assigned Seats": resp.data.assignedSeat,
                                        "Quantity": resp.data.quantity,
                                        "Status": resp.data.status === 'ACTIVE' ? (
                                            <div className={`${styles['status-gws']}`}>
                                                <span className="status-active"></span>
                                                <div className="py-1 text-muted text-small">Active</div>
                                            </div>
                                        ) : resp.data.status === 'CANCELLED' ? (
                                            <div className={`${styles['status-gws']}`}>
                                                <span className="status-failed"></span>
                                                <div className="py-1 text-muted text-small">Cancelled</div>
                                            </div>
                                        ) : resp.data.status === 'PENDING' ? (
                                            <div className={`${styles['status-gws']}`}>
                                                <span className="status-pending"></span>
                                                <div className="py-1 text-muted text-small">Pending</div>
                                            </div>
                                        ) : (
                                            resp.data.status
                                        ),
                                        "Auto Renew": (resp.data.autoRenew ? "Yes" : "No"),
                                        "Subscription Type": resp.data.subscriptionType,
                                        "RRP (Inc GST)": `$${resp.data.rrp}`,
                                        "Price (Ex GST)": `$${resp.data.price}`,
                                        "Start Date": resp.data.startDate,
                                        "Renewal Date": resp.data.renewalDate,
                                    }
                                );
                            setTimeout(() => {
                                setTableSubscriptionTable(f);         // Setting Table Data
                            }, 50);
                            let d = [];
                            ColumnNameSubscriptionTable.map((td) => {
                                if (td === "RRP (Inc GST)" || td === "Price (Ex GST)" || td === "Quantity") {
                                    d.push({
                                        id: `${td}`,
                                        name: <div>{td}</div>,
                                        selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                                        right: true,
                                    })
                                }
                                else if (td === "Billing Term") {
                                    d.push({
                                        id: `${td}`,
                                        name: <div>{td}
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={<Tooltip className={`${styles['gws-tooltip']}`}>Billing Term (Commitment Term)</Tooltip>}
                                            >
                                                <span className="infoIcon-support-table ml-1"></span>
                                            </OverlayTrigger></div>,
                                        selector: row => <div className="text-wrap">
                                            {row[`${td}`]}</div>,
                                        left: true,
                                        grow: 2,
                                        allowOverflow: true,
                                    })
                                }
                                else {
                                    d.push({
                                        id: `${td}`,
                                        name: <div>{td}</div>,
                                        selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                                        left: true,
                                    })
                                }
    
                            })
                            setColumnsSubscriptionTable(d);     // Setting Column Data
                        }
    
                    })
                    .catch(error => {
                        if (error?.status == 401 || error?.response?.status == 401) {
                        }
                        else {
                        }
    
                    });
    };

    // Get Table Data with Filters, Pagination, Search  Functionality
    async function GetTableData(page, newPerPage, search, status, planName, autoRenew, fromDate, toDate, sortCol, sortDir, serviceId, customerId, isFilter, appendData, duration) {
        setInitialLoading(false);
        try {
        search = "";
        (status === undefined || status === null || status === "null") ? status = "" : status = status;
        (fromDate === undefined || fromDate === "undefined/undefined/" || fromDate === null || fromDate === "null") ? fromDate = "" : fromDate = fromDate;
        (toDate === undefined || toDate === "undefined/undefined/" || toDate === null || toDate === "null") ? toDate = "" : toDate = toDate;
        (planName === undefined || planName === null || planName === "null") ? planName = "" : planName = planName;
        (customerId === undefined || customerId === null || customerId === "null") ? customerId = "" : customerId = customerId;
        (autoRenew === undefined || autoRenew === null || autoRenew === "null") ? autoRenew = "" : autoRenew = autoRenew;
        (sortCol === undefined || sortCol === null || sortCol === "null") ? sortCol = "" : sortCol = sortCol;
        (sortDir === undefined || sortDir === null || sortDir === "null") ? sortDir = "" : sortDir = sortDir;
        isFilter = (isFilter === undefined || isFilter === null) ? false : isFilter;
        (duration === undefined || duration === null || duration === "null" || duration === "All") ? duration = "" : duration = duration; 

                const response = await api.get(`${RENEWALSETTINGS_TABLE}?IntegrationCode=${integrationCode}&ServiceId=${serviceId}&CustomerCode=${customerId}&PageNo=${page}&PageSize=${newPerPage}&SearchText=${search}&SortCol=${sortCol}&SortDir=${sortDir}&Duration=${duration}`, config);
                setInitialLoading(true);
                setInfinityLoading(false);
                if (response.status === 204) {
                // Clear error states first
                setServerErrorCustomer(false);
                setEmptyCustomer(false);
                setEmptyCustomerFilters(false);
                setEmptyCustomerSearch(false);
                if (customerId) {
        // If we have a selected customer (customer search/filter case)
        setEmptyCustomerSearch(true);
    } else {
        // If no customer is selected (general empty case)
        setEmptyCustomer(true);
    }
                return;
               }
                // handle success
                else {
            // Reset error and empty states
            setServerErrorCustomer(false);
            setEmptyCustomer(false);
            setEmptyCustomerFilters(false);
            setEmptyCustomerSearch(false);
                    let f = [];
                    setTotalRows(response.data.totalCount);
                    (response.data.content).map((td) => {
                        f.push
                            (
                                {
                                    "Customer": td.customerName,
                                    "Service": td.serviceName,
                                    "Plan": <>{td.planName}
                                        <div title={td.vendorSubscriptionId} className={`py-1 ${styles['csp-vendor-id']}`}>{td.vendorSubscriptionId}</div>
                                    </>,
                                    "Commitment Term (Billing Frequency)": td.billingTerm,
                                    "Quantity": td.quantity,
                                    "Start Date": td.startDate,
                                    "End Date": td.endDate,
                                    "Auto Renew": td.autoRenew,
                                    "Period": td.period,
                                    "Days Remaining": td.remainingDays,
                                    "scheduledPlan": td.scheduledPlan,
                                    "scheduledQuantity": td.scheduledQuantity,
                                    "scheduledBillingCommitmentterm": td.scheduledBillingCommitmentterm,
                                    "renewalEndDate": td.renewalEndDate,
                                    "Renewal Settings": <span className={((td.billingTerm.includes("Annual Plan") || (td.planType && td.planType.toLowerCase()==="ezdeal")) || td.serviceName==="Microsoft CSP") ? 'renewal-change-status cpointer' : 'renewal-change-status-disabled'}
                                        onClick={() => {
                                            if (td.serviceName == "Google Workspace" && (td.billingTerm.includes("Annual Plan") || td.planType.toLowerCase()==="ezdeal")) {
                                                GetActionsData(td.subscriptionId,GOOGLE_WORKSPACE_SUBSCRIPTION_DETAILS); 
                                                setRenewalSettingsActionActionPopupModalOpen(true);
                                                setActionsSubscriptionId(td.subscriptionId); 
                                                setActionsPlanName(td.planName)

                                                // if (td.isAddonPlan) {
                                                //     navigate("/management-console/gws", { state: { customerId: td.customerCode, subscriptionId: td.subscriptionId, planName: td.basePlanName, isRenewalSettings: true } })
                                                // }
                                                // else {
                                                //     navigate("/management-console/gws", { state: { customerId: td.customerCode, subscriptionId: td.subscriptionId, planName: td.planName, isRenewalSettings: true } })
                                                // }
                                            }
                                            else if(td.serviceName == "Microsoft CSP") {
                                                setActionsSelectedRow(td);
                                                GetActionsData(td.subscriptionId,CSP_SUBSCRIPTION_DETAILS); 
                                                setRenewalSettingsActionPopupModalOpenCSP(true);
                                                setActionsSubscriptionId(td.subscriptionId); 
                                                setActionsPlanName(td.planName)

                                                // if (td.isAddonPlan) {
                                                //     navigate("/management-console/csp", { state: { customerId: td.customerCode, subscriptionId: td.subscriptionId, planName: td.basePlanName, isRenewalSettings: true } })
                                                // }
                                                // else {
                                                //     navigate("/management-console/csp", { state: { customerId: td.customerCode, subscriptionId: td.subscriptionId, planName: td.planName, isRenewalSettings: true } })
                                                // }
                                            }
                                        }}
                                        ></span>

                                }
                            );
                    })
                    setTimeout(() => {
                        if (appendData) {
                            setTable(table => [...table, ...f])            // Setting Table Data
                        } else {
                            setTable(f)
                        }
                    }, 50);
                    let d = [];
                    ((!isReadRenewalSettings) ? ColumnName : ColumnNameNoAction).map((td) => {
                        // if (td === "Status") {
                        //     d.push({
                        //         id: `${td}`,
                        //         name: <div>{td}</div>,
                        //         selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                        //         center: true,
                        //         allowOverflow: true,
                        //     })
                        // }
                        // else 
                        if (td === "Start Date") {
                            d.push({
                                id: `${td}`,
                                name: <div>
                                    {isDescStartDate === null && <>{td}<span className="sort-icon-disabled sort-icon-sub-position mx-1" onClick={() => { setScrollToDivTop(true); setSortDir("DESC"); setIsDescStartDate(true); setSortCol("sortStartDate"); setIsDescEndDate(null); }}></span></>}
                                    {isDescStartDate === true && <>{td}<span className="sort-icon-asc sort-icon-sub-position mx-1" onClick={() => { setScrollToDivTop(true); setSortDir("ASC"); setIsDescStartDate(false); setSortCol("sortStartDate"); }}></span></>}
                                    {isDescStartDate === false && <>{td}<span className="sort-icon-desc sort-icon-sub-position mx-1" onClick={() => { setScrollToDivTop(true); setSortDir("DESC"); setIsDescStartDate(true); setSortCol("sortStartDate"); }}></span></>}
                                </div>,
                                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                                left: true,
                                allowOverflow: true,
                                grow: 1.2
                            })
                        }
                        else if (td === "End Date") {
                            d.push({
                                id: `${td}`,
                                name: 
                                <div className='text-nowrap'>
                                    {isDescEndDate === null && <>{td}<span className="sort-icon-disabled sort-icon-sub-position mx-1" onClick={() => { setScrollToDivTop(true); setSortDir("DESC"); setIsDescEndDate(true); setSortCol("sortEndDate"); setIsDescStartDate(null); }}></span></>}
                                    {isDescEndDate === true && <>{td}<span className="sort-icon-asc sort-icon-sub-position mx-1" onClick={() => { setScrollToDivTop(true); setSortDir("ASC"); setIsDescEndDate(false); setSortCol("sortEndDate"); }}></span></>}
                                    {isDescEndDate === false && <>{td}<span className="sort-icon-desc sort-icon-sub-position mx-1" onClick={() => { setScrollToDivTop(true); setSortDir("DESC"); setIsDescEndDate(true); setSortCol("sortEndDate"); }}></span></>}
                                </div>,
                                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                                left: true,
                                allowOverflow: true,
                                grow: 1.2
                            })
                        }
                        else if (td === "Renewal Settings") {
                            d.push({
                                id: `${td}`,
                                name: <div className='text-center d-none-mobile'>{td}</div>,
                                selector: row => <div className="text-wrap d-none-mobile">{row[`${td}`]}</div>,
                                center: true,
                                allowOverflow: true,
                            })

                        }
                        else if(td === "Auto Renew"){
    d.push({
        id: `${td}`,
        name: <div className='text-nowrap text-center'>
            {isDescAutoRenew === null && <>{td}<span className="sort-icon-disabled sort-icon-sub-position mx-1" onClick={() => { setScrollToDivTop(true); setSortDir("DESC"); setIsDescAutoRenew(true); setSortCol("autoRenew"); setIsDescEndDate(null); setIsDescStartDate(null); setIsDescDaysRemaining(null); }}></span></>}
            {isDescAutoRenew === true && <>{td}<span className="sort-icon-asc sort-icon-sub-position mx-1" onClick={() => { setScrollToDivTop(true); setSortDir("ASC"); setIsDescAutoRenew(false); setSortCol("autoRenew"); }}></span></>}
            {isDescAutoRenew === false && <>{td}<span className="sort-icon-desc sort-icon-sub-position mx-1" onClick={() => { setScrollToDivTop(true); setSortDir("DESC"); setIsDescAutoRenew(true); setSortCol("autoRenew"); }}></span></>}
        </div>,
        selector: row => {
            const status = String(row[td]).toLowerCase();
            if (status === 'scheduled') {
                return (
                    <div className="text-wrap text-center d-flex align-items-center justify-content-center">
                        <span>Scheduled</span>
                        <OverlayTrigger
                        placement="left"
                        trigger={['hover', 'focus']}
                        defaultShow={false}
                        rootClose
        overlay={
        <Tooltip id="subscription-details-tooltip" className="custom-tooltip">
            <div className="bg-white rounded shadow-lg border border-gray-200 w-auto">
                <div className="header py-3 px-4 d-flex justify-content-between align-items-center border-bottom">
                    <h3 className="mb-0">Scheduled Renewal Details</h3>
                </div>
                <div className="content p-4">
                    <Row className="mb-4">
                        <Col md={8}>
                            <label className={`text-muted mb-2 ${styles?.['popup-label'] || ''}`}>Plan</label>
                            <p className="mb-0 fw-medium">{row["scheduledPlan"]}</p>
                        </Col>
                        <Col md={4}>
                            <label className={`text-muted mb-2 ${styles?.['popup-label'] || ''}`}>Quantity</label>
                            <p className="mb-0 fw-medium">{row["scheduledQuantity"]}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={8}>
                            <label className={`text-muted mb-2 ${styles?.['popup-label'] || ''}`}>Commitment Term (Billing Frequency)</label>
                            <p className="mb-0 fw-medium">{row["scheduledBillingCommitmentterm"]}</p>
                        </Col>
                        <Col md={4}>
                            <label className={`text-muted mb-2 ${styles?.['popup-label'] || ''}`}>Aligned End Date</label>
                            <p className="mb-0 fw-medium">{row["renewalEndDate"]}</p>
                        </Col>
                    </Row>
                </div>
            </div>
        </Tooltip>
    }
>
    <div 
        className={`infoIcon ms-1 cursor-pointer ${styles['info-icon']}`}
        role="button"
        tabIndex={0}
        onMouseEnter={() => {
            if (tooltipTimeout.current) {
                clearTimeout(tooltipTimeout.current);
            }
            setTooltipVisible(true);
        }}
        onMouseLeave={() => {
            tooltipTimeout.current = setTimeout(() => {
                setTooltipVisible(false);
            }, 50);
        }}
    >
        &nbsp;
    </div>
</OverlayTrigger>
                    </div>
                );
            } else {
                return (
                    <div className={`text-wrap text-center ${status === 'on' ? 'text-success' : 'text-danger'}`}>
                        {row[td]}
                    </div>
                );
            }
        },
        left: true,
        allowOverflow: true,
    })
                        }
                        else if (td === "Commitment Term (Billing Frequency)") {
                            d.push({
                id: `${td}`,
                name: <div>{td}</div>,
                selector: row => <div className="text-wrap">
                    {row[`${td}`]}</div>,
                left: true,
                grow: 2,
                allowOverflow: true,
            })
                        }
                        else if (td === "Quantity") {
                            d.push({
                                id: `${td}`,
                                name: <div>{td}</div>,
                                selector: row => <div className="text-wrap text-right">{row[`${td}`]}</div>,
                                right: true,
                                grow: 1.2,
                            })
                        }
                        else if (td === "Days Remaining") {
                d.push({
                    id: `${td}`,
                    name: <div>
                        {isDescDaysRemaining === null && <>{td}<span className="sort-icon-disabled sort-icon-sub-position mx-1" onClick={() => { setScrollToDivTop(true); setSortDir("DESC"); setIsDescDaysRemaining(true); setSortCol("remainingDayNumber"); setIsDescEndDate(null); setIsDescStartDate(null); setIsDescAutoRenew(null); }}></span></>}
                        {isDescDaysRemaining === true && <>{td}<span className="sort-icon-asc sort-icon-sub-position mx-1" onClick={() => { setScrollToDivTop(true); setSortDir("ASC"); setIsDescDaysRemaining(false); setSortCol("remainingDayNumber"); }}></span></>}
                        {isDescDaysRemaining === false && <>{td}<span className="sort-icon-desc sort-icon-sub-position mx-1" onClick={() => { setScrollToDivTop(true); setSortDir("DESC"); setIsDescDaysRemaining(true); setSortCol("remainingDayNumber"); }}></span></>}
                    </div>,
                    selector: row => <div className="text-wrap">{row[td]}</div>,
                    left: true,
                })
            }
                        else {
                            d.push({
                                id: `${td}`,
                                name: <div>{td}</div>,
                                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                                left: true,
                            })
                        }

                    })
                    setColumns(d);       // Setting Column Data
                }
            }
            catch (error) {
            console.error('Error fetching table data:', error);
            setInitialLoading(true);
            setInfinityLoading(false);
            if (error?.status === 401 || error?.response?.status === 401) {
                Logout();
                return;
            }
            setServerErrorCustomer(true);
            setEmptyCustomer(false);
            setEmptyCustomerFilters(false);
            setEmptyCustomerSearch(false);
            }
    };

    async function GetAllServices(selectedServiceName) {
    try {
        const resp = await api.get(`${GET_SERVICES}`, config);
        
        const formattedServices = resp.data
            .filter(service => service.serviceType === "ONLINE")
            .map(service => ({
                id: service.id,
                name: service.name
            }));
        
        setServiceOptions(formattedServices);

        if (selectedServiceName) {
            const selectedService = resp.data.find(service => 
                service.name === selectedServiceName
            );
            
            if (selectedService) {
                setServiceId(selectedService.id);
                setIntegrationCode(selectedService.integrationCode);
                setSelectedService(selectedServiceName);
            }
        } else if (formattedServices.length > 0) {
                // Default to Microsoft CSP if no service is selected
                const defaultService = resp.data.find(service => service.name === "Microsoft CSP");
                if (defaultService) {
                    setSelectedService(defaultService.name);
                    setServiceId(defaultService.id);
                    setIntegrationCode(defaultService.integrationCode);
                }
        }

    } catch (error) {
        console.error('Error fetching services:', error);
        if (error?.status === 401 || error?.response?.status === 401) {
            Logout();
            return;
        }
        
        setToastMessage(ServiceFetchingErrorToast);
        setToastError(true);
        setTimeout(() => {
            setToastError(false);
        }, 2000);
    }
    }

    // Function to call all dropdown values in Plans Section in Filter Box
    async function GetAllPlans() {
        await api.get(`${DASHBOARD_SUBSCRIPTIONS_PLAN_LIST}?integrationCode=${integrationCode}`, config)
            .then(resp => {
                setPlanData(resp.data);
            })
            .catch(error => {
                if (error?.status == 401 || error?.response?.status == 401) {
                    Logout();
                }
                else {
                }
            });
    };

    // Function Triggers when Page Number is Changed by Customer
    const handlePageChange = (pageNo) => {
        GetTableData(pageNo, pageSize, searchValueTable, status, planName, autoRenew, fromDate, toDate, sortCol, sortDir, serviceId, customerId);
        setPageNo(pageNo);
    };

    // Function Triggers when Rows Per Page is Changed by Customer
    const handlePerRowsChange = async (newPerPage, pageNo) => {
        setPageNo(pageNo);
        setPageSize(newPerPage);
        GetTableData(pageNo, newPerPage, searchValueTable, status, planName, autoRenew, fromDate, toDate, sortCol, sortDir, serviceId, customerId);
    };

    // Function to trigger getTable data function on change in sorting icons and sort field name,by default this function trigger initial request
    useEffect(() => {
    if (!isFirstSortEffect.current) { // Only run if it's not the first render
        setPageNo(1);
        GetTableData(
            1, 
            pageSize, 
            searchValueTable, 
            status, 
            planName, 
            autoRenew, 
            fromDate, 
            toDate, 
            sortCol, 
            sortDir, 
            serviceId, 
            customerId
        );
    } else {
        isFirstSortEffect.current = false; // Set to false after first render
    }
    }, [isDescEndDate, isDescStartDate, isDescAutoRenew, isDescDaysRemaining]);



   useEffect(() => {
    currentServiceRef.current = selectedService;
   }, [selectedService]);

    useEffect(() => {
    let timeoutId; 
    if (refershFlagAction) {
        setScrollToDivTop(true);
        setPageNo(1);
        GetTableData(1, pageSize, searchValueTable, status, planName, autoRenew, fromDate, toDate, sortCol, sortDir, serviceId, customerId, true);
        setRefershFlagAction(false);
        if (selectedService === "Microsoft CSP") {
            // console.log("Setting timeout for Microsoft CSP");
            // timeoutId = 
            setTimeout(() => {
                // console.log("Timeout executing---current service:", currentServiceRef.current);
                if (currentServiceRef.current === "Microsoft CSP") {
                    setScrollToDivTop(true);
                    setPageNo(1);
                    GetTableData(1, pageSize, searchValueTable, status, planName, autoRenew, fromDate, toDate, sortCol, sortDir, serviceId, customerId, true);
                } else {
                    // console.log("Service changed to other service than csp");
                }
            }, 35000);
        }
    }

    // return () => {
    //     if (timeoutId && currentServiceRef.current !== "Microsoft CSP") {
    //         clearTimeout(timeoutId);
    //     }
    // };
    }, [refershFlagAction]);
    
    // React Data Table Custom Theme
    createTheme('solarized', REACT_DATA_TABLE_COLOR_THEME);

    // React Data Table Custom Styles
    const customStyles = {
        tableWrapper: {
            style: {
                minHeight: '300px'
            },
        },
        rows: {
            style: {
                minHeight: '60px',  // override the row height
            },
        },
        cells: {
            style: {
                paddingLeft: '8px', // override the cell padding for data cells
                paddingRight: '8px',
                // textAlign: 'center',
                fontSize: '0.9rem',
                fontWeight: '400',
                paddingTop: '0px',
                paddingBottom: '0px',
            },
        },
        headCells: {
            style: {
                paddingLeft: '10px', // override the cell padding for head cells
                paddingRight: '10px',
                paddingTop: '10px',
                paddingBottom: '10px',
                backgroundColor: BACKGROUND_COLOR_SECONDARY,
                fontWeight: '500',
                fontSize: '0.875rem',
                color: `${TEXT_SECONDARY} !important`,
            },
        },
    };

    // Function to handle the lazy loading calls invoked from the Lazy Table
    const handleLazyCalls = async () => {
    setInfinityLoading(true);
    setPageNo((prev) => {
        const newPageNumber = prev + 1;
        
        // Get all current values for consistent API calls
        const currentParams = {
            pageNumber: newPageNumber,
            pageSize: pageSize,
            searchValue: searchValueTable || "",
            status: status || "",
            planName: planName || "",
            autoRenew: autoRenew || "",
            fromDate: fromDate || "",
            toDate: toDate || "",
            sortColumn: sortCol || "remainingDayNumber",
            sortDirection: sortDir || "ASC",
            serviceId: serviceId || "",
            customerId: customerId || "",
            duration: selectedDuration.split(" ")[0] || "All"
        };

        // Call GetTableData with all current parameters
        (async () => await GetTableData(
            currentParams.pageNumber,
            currentParams.pageSize,
            currentParams.searchValue,
            currentParams.status,
            currentParams.planName,
            currentParams.autoRenew,
            currentParams.fromDate,
            currentParams.toDate,
            currentParams.sortColumn,
            currentParams.sortDirection,
            currentParams.serviceId,
            currentParams.customerId,
            false, // isFilter
            true,  // appendData
            currentParams.duration
        ))();
        
        return newPageNumber;
    });
};

    // Function to trigger getTable data function 
    useEffect(() => {
        // GetTableData(pageNo, pageSize, searchValueTable, status, planName, autoRenew, fromDate, toDate, sortCol, sortDir, serviceId, customerId);
        GetAllServices();
        GetAllCustomers();
        GetDurationOptions();
    }, [])

    //on tab change move make scroll to top true
    useEffect(() => {
        setScrollToDivTop(true);
        setPageNo(1);
    }, [tabName])

    // Define a function to handle selecting a service from the dropdown
     const handleSelectService = (selectedServiceName) => {
        setPageNo(1);
        // if (selectedServiceName === "All Services") {
        //     // If "All Services" is selected, set service id to empty and integration code to empty string
        //     setSelectedService("All Services");
        //     setServiceId(18);
        //     setIntegrationCode("microsoftsaas");
        // } else {
            // If a specific service is selected, fetch its details
            GetAllServices(selectedServiceName);
        // }
    };

 useEffect(() => {
    setScrollToDivTop(true);
    setPageNo(1);

    // Preserve the current values
    const currentDuration = selectedDuration.split(" ")[0] || 'All';
    const currentCustomerId = customerId || null;

    if (tableFilterApplied) {
        GetTableData(
            1, 
            pageSize, 
            searchValueTable, 
            status, 
            planName, 
            autoRenew, 
            fromDate, 
            toDate, 
            sortCol, 
            sortDir, 
            serviceId,
            currentCustomerId,
            true,
            false,
            currentDuration
        );
    } else {
        if (!isFirstServiceEffect.current) {
            GetTableData(
                1, 
                pageSize, 
                searchValueTable, 
                status, 
                planName, 
                autoRenew, 
                fromDate, 
                toDate, 
                sortCol, 
                sortDir, 
                serviceId,
                currentCustomerId,
                false,
                false,
                currentDuration
            );
        } else {
            isFirstServiceEffect.current = false;
        }
    }
}, [selectedService, integrationCode, serviceId]);

    // Search Box Focus on Open
    useEffect(() => {
        searchInputRef.current && searchInputRef.current.focus();
    }, [searchIconFlag]);

    // Download CSV File 
    const handleDownloadReport = async () => {
        try {
            const durationValue = selectedDuration === "All" ? "" : selectedDuration.split(" ")[0];
            const response = await api.get(
                `${RENEWAL_SETTINGS_SUBS_DOWNLOAD}?duration=${durationValue || ''}&integrationCode=${integrationCode}&serviceId=${serviceId}&customerCode=${customerId || ''}&PageNo=${pageNo}&PageSize=${pageSize}&searchText=${searchValueTable || ''}&SortCol=${sortCol || ''}&SortDir=${sortDir || ''}`,
                configBlob
            );

            if (response.status === 204) {
                setToastMessage(GenericDownloadEmptyToast);
                setToastError(true);
                setTimeout(() => {
                    setToastError(false);
                }, 2000);
                return;
            }

            downloadFile({
                data: response.data,
                fileName: `${localStorage.getItem("reseller_id")}_${String(date.getDate()).padStart(2, '0')}${String(date.getMonth() + 1).padStart(2, "0")}${date.getFullYear()}_Subscriptions Renewal.csv`,
                fileType: "application/csv",
            });

        } catch (error) {
            console.error('Error downloading report:', error);
            if (error?.status === 401 || error?.response?.status === 401) {
                Logout();
                return;
            }

            setToastMessage(DownloadReportErrorToast);
            setToastError(true);
            setTimeout(() => {
                setToastError(false);
            }, 2000);
        }
    };

    return (
        <>
            <Toast toastSetUseState={setToastSuccess} toastCheckUseState={toastSuccess} toastType="success" toastIconColor={TOAST_SUCCESS_ICON_COLOR} toastMessage={toastMessage} />
            <Toast toastSetUseState={setToastError} toastCheckUseState={toastError} toastType="danger" toastIconColor={TOAST_ERROR_ICON_COLOR} toastMessage={toastMessage} />
            {
                renewalSettingsActionPopupModalOpen &&
                <GWSRenewalSettingsPage subscriptionTableAPIData={subscriptionTableAPIData} subscriptionId={actionsSubscriptionId} planName={actionsPlanName}
                    renewalSettingsActionPopupModalOpen={renewalSettingsActionPopupModalOpen} setRenewalSettingsActionActionPopupModalOpen={setRenewalSettingsActionActionPopupModalOpen}
                    columnsSubscriptionTable={columnsSubscriptionTable} tableSubscriptionTable={tableSubscriptionTable}
                    GetTableData={GetTableData} setToastSuccess={setToastSuccess} setToastError={setToastError} setToastMessage={setToastMessage}
                    setSubscriptionTableAPIData={setSubscriptionTableAPIData} setRefershFlagAction={setRefershFlagAction}
                />
            }
            {
                renewalSettingsActionPopupModalOpenCSP &&
                <CSPRenewalSettingsPage subscriptionTableAPIData={subscriptionTableAPIData} subscriptionId={actionsSubscriptionId} planName={actionsPlanName} setSubscriptionTableAPIData={setSubscriptionTableAPIData}
                    renewalSettingsActionPopupModalOpen={renewalSettingsActionPopupModalOpenCSP} setRenewalSettingsActionPopupModalOpen={setRenewalSettingsActionPopupModalOpenCSP} Logout={Logout}
                    columnsSubscriptionTable={columnsSubscriptionTable} tableSubscriptionTable={tableSubscriptionTable} actionsSelectedRow={actionsSelectedRow}
                    GetTableData={GetTableData} setToastSuccess={setToastSuccess} setToastError={setToastError} setToastMessage={setToastMessage} isNcePlan={isNcePlan} setIsNcePlan={setIsNcePlan} setRefershFlagAction={setRefershFlagAction}
                />
            }
            <div className="main-content">
                {!isDisabledRenewalSettings && <div className="customer-management-console-main-div mb-5">
                <div className="mgmt-console-table-row">
                <div className="mb-3">
                <Col className={`py-0  ${styles['renewal-settings-table']}`}>
                    <div className={`${styles['renewal-settings-table-inner']} ${styles['table-details']} details-box`}>
                        <div className='my-4 position-relative'>
                            <Container fluid>
                            <Row>
                                <Col md={3} lg={3} className='my-4 d-flex align-items-center' >
                                    <h2>Subscriptions {!emptyCustomer && !emptyCustomerFilters && !emptyCustomerSearch && initialLoading && <span className='text-muted text-large'>({totalRows})</span>}</h2>
                                
                                {/* Mobile-only download button */}
        <div className='d-md-none ms-auto'>
            <Button
                variant="light"
                className="btn-border-light"
                onClick={handleDownloadReport}
            >
                Download Report
            </Button>
        </div>
                                </Col>

                                {/* Desktop Search and Filter lg={5}*/}
                                <Col className={`px-1 d-none d-md-block mb-2 my-4 ${styles['d-md-px-0']} ${styles['d-lg-px-1']}`} md={3} >
                                                                 <Row className={`justify-content-end align-items-center ${styles['search-tablet-view']}`}>
                                                                    {
                                                                    !serverErrorCustomer && !emptyCustomer && 
                                                                    <Col className="d-flex align-items-center justify-items-center justify-content-end position-relative">
                                                                        {!searchIconFlag &&
                                                                            <span className="mx-3">&nbsp;</span>
                                                                        }
                                                                        {!searchIconFlag && !emptyCustomerFilters &&
                                                                            <span className="search mx-3 my-2 cpointer" onClick={() => { setSearchIconFlag(!searchIconFlag); }}>&nbsp;</span>
                                                                        }
                                                                        {searchIconFlag &&
                                                                          !emptyCustomerFilters &&
    <InputGroup className="search-input-box search-input-box-xl mx-0">
        <InputGroup.Text id="basic-addon2" className="search-input-box-btn">
            <span className="searchgrey cpointer">&nbsp;</span>
        </InputGroup.Text>
        <Form.Control
            placeholder="Search all Customers"
            aria-label="Search"
            aria-describedby="basic-addon2"
            className="search-input-box-input"
            value={searchValueTable}
            onChange={handleCustomerSearch}
            ref={searchInputRef}
        />
        <div className={`servcies-dropdown dropdown-specialclass-fortwo dropdown show dynamic-options-position ${styles['search-dropdown']}`}>
    {showCustomerDropdown && (
        <div 
            className={`dynamic-options-block dropdown-menu show ${styles?.['search-dropdown-options']}`}
        onScroll={(e) => {
        // Prevent event propagation to stop it from triggering the click outside handler
        e.stopPropagation();
        // Keep dropdown visible while scrolling
        if (searchValueTable?.length > 2) {
            setShowCustomerDropdown(true);
        }
    }}
    >
        {(customerOptionsFiltered.length > 0 ? customerOptionsFiltered : customerOptions).map(option => (
           <NavDropdown.Item
                key={option.id}
                eventKey={option.id}
                className={selectedCustomer === option.id ? "selected" : ""}
                onMouseDown={(e) => {
                    e.preventDefault(); // Prevent default behavior
                    e.stopPropagation(); // Stop event bubbling
                    setScrollToDivTop(true);
                    const newCustomerId = option.id === 'All' ? null : option.id;
                     setSelectedCustomer(option.id);
                     setCustomerId(newCustomerId);
                     setSearchValueTable(option.name);
                     setShowCustomerDropdown(false);
                     setPageNo(1);
                     setTable([]);
                    
                    // Make API call with empty search text and selected customer ID
                    // setTimeout(()=>{

                    const currentDuration = selectedDuration.split(" ")[0] || 'All';

                GetTableData(
                    1,
                    pageSize,
                    "",
                    status,
                    planName,
                    autoRenew,
                    fromDate,
                    toDate,
                    sortCol,
                    sortDir,
                    serviceId,
                    newCustomerId,
                    true,
                    false,
                    currentDuration // Pass the current selected duration
                ); 
                    // }, 1000)
                    
                    // Update UI after API call
                    setSearchValueTable(option.name);
                    setShowCustomerDropdown(false);
                }}
            >
                {option.name}
            </NavDropdown.Item>
        ))}
    </div>
)}
        </div>
        <InputGroup.Text
            id="basic-addon2"
            className="search-input-box-btn search-input-box-close"
            onClick={() => {
                setPageNo(1);
                setTable([]);
                setScrollToDivTop(true);
                setSearchValueTable("");
                setSearchIconFlag(!searchIconFlag);
                setShowCustomerDropdown(false);
                setSelectedCustomer('All');
                setCustomerId(null);
                const currentDuration = selectedDuration.split(" ")[0] || 'All';
                GetTableData(
                    1,
                    pageSize,
                    "",
                    status,
                    planName,
                    autoRenew,
                    fromDate,
                    toDate,
                    sortCol,
                    sortDir,
                    serviceId,
                    null,
            true,
            false,
            currentDuration
                );
            }}
        >
            <span className="closebtn">&nbsp;</span>
        </InputGroup.Text>
    </InputGroup>
}

                                                                    </Col>}
                                                                </Row>
                                </Col>

                                {/* Mobile Search and Filter px-1 */}
<Col className="d-block d-md-none" md={12}>
    <Row className="justify-content-start align-items-center">
        {!serverErrorCustomer && !emptyCustomer && 
            <Col className="d-flex align-items-center justify-items-center justify-content-end position-relative">
             {!emptyCustomerFilters &&
                    <InputGroup className="search-input-box">
                        <InputGroup.Text id="basic-addon2" className="search-input-box-btn">
                            <span className="searchgrey cpointer">&nbsp;</span>
                        </InputGroup.Text>
                        <Form.Control
                            placeholder="Search all Customers"
                            aria-label="Search"
                            aria-describedby="basic-addon2"
                            className="search-input-box-input"
                            value={searchValueTable}
                            onChange={handleCustomerSearch}
                            ref={searchInputRef}
                        />
                        <div className="servcies-dropdown dropdown-specialclass-fortwo dropdown show dynamic-options-position-wide"
                        >
                            {showCustomerDropdown && (
                                <div 
                                    className={`dynamic-options-block dropdown-menu show dropdown-menu-mobile ${styles['dropdown-menu-mobile-options']}`}
                                    onScroll={(e) => {
                                        e.stopPropagation();
                                        if (searchValueTable?.length > 2) {
                                            setShowCustomerDropdown(true);
                                        }
                                    }}
                                >
                                    {(customerOptionsFiltered.length > 0 ? customerOptionsFiltered : customerOptions).map(option => (
                                        <NavDropdown.Item
                                            key={option.id}
                                            eventKey={option.id}
                                            className={selectedCustomer === option.id ? "selected" : ""}
                                            onMouseDown={(e) => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                setScrollToDivTop(true);
                                                setPageNo(1);
                                                setTable([]);
                                                
                                                const newCustomerId = option.id === 'All' ? null : option.id;
                                                setSelectedCustomer(option.id);
                                                setCustomerId(newCustomerId);
                                                setSearchValueTable(option.name);
                                                setShowCustomerDropdown(false);
                                                
                                                GetTableData(
                                                    1,
                                                    pageSize,
                                                    "",
                                                    status,
                                                    planName,
                                                    autoRenew,
                                                    fromDate,
                                                    toDate,
                                                    sortCol,
                                                    sortDir,
                                                    serviceId,
                                                    newCustomerId,
                                                    true
                                                );
                                            }}
                                        >
                                            {option.name}
                                        </NavDropdown.Item>
                                    ))}
                                </div>
                            )}
                        </div>
                        <InputGroup.Text
                            id="basic-addon2"
                            className="search-input-box-btn search-input-box-close"
                            onClick={() => {
                                setPageNo(1);
                                setTable([]);
                                setSearchValueTable("");
                                setSearchIconFlag(!searchIconFlag);
                                setShowCustomerDropdown(false);
                                setSelectedCustomer('All');
                                setCustomerId(null);
                                GetTableData(
                                    1,
                                    pageSize,
                                    "",
                                    status,
                                    planName,
                                    autoRenew,
                                    fromDate,
                                    toDate,
                                    sortCol,
                                    sortDir,
                                    serviceId,
                                    null,
                                    true
                                );
                            }}
                        >
                            <span className="closebtn">&nbsp;</span>
                        </InputGroup.Text>
                    </InputGroup>
                }
            </Col>
        }
    </Row> 
</Col>


                                {/* Service Type and Service Selection Dropdowns */}
                                {!serverErrorCustomer &&
                                    <>
        <Col xs={12} md={2} lg={2} className='my-4'>
        <Form.Group>
    <NavDropdown
        className={`servcies-dropdown dropdown-specialclass-fortwo dropdown-services ${styles['dropdown-services-renewal']}`}
        title={selectedService}
        id="cloud-service-dropdown"
        onSelect={handleSelectService}
        value={selectedService}
    >
        {/* <NavDropdown.Item
                                eventKey="All Services"
                                className={selectedService === "All Services" ? "selected" : ""}
                            >All Services</NavDropdown.Item> */}
                            <NavDropdown.Item
                                eventKey="Microsoft CSP"
                                className={selectedService === "Microsoft CSP" ? "selected" : ""}
                            >Microsoft CSP</NavDropdown.Item>
                            {/* <NavDropdown.Item
                                eventKey="Microsoft CSP - Azure"
                                className={selectedService === "Microsoft CSP - Azure" ? "selected" : ""}
                            >Microsoft CSP - Azure</NavDropdown.Item> */}
                            <NavDropdown.Item
                                eventKey="Google Workspace"
                                className={selectedService === "Google Workspace" ? "selected" : ""}
                            >Google Workspace</NavDropdown.Item>
                            {/* <NavDropdown.Item
                                eventKey="Google Cloud"
                                className={selectedService === "Google Cloud" ? "selected" : ""}
                            >Google Cloud</NavDropdown.Item>
                            <NavDropdown.Item
                                eventKey="Acronis Cyber Cloud"
                                className={selectedService === "Acronis Cyber Cloud" ? "selected" : ""}
                            >Acronis Cyber Cloud</NavDropdown.Item>
                            <NavDropdown.Item
                                eventKey="Avast CloudCare"
                                className={selectedService === "Avast CloudCare" ? "selected" : ""}
                            >Avast CloudCare</NavDropdown.Item>
                            <NavDropdown.Item
                                eventKey="IBM Cloud"
                                className={selectedService === "IBM Cloud" ? "selected" : ""}
                            >IBM Cloud</NavDropdown.Item> */}
    </NavDropdown>
</Form.Group>
        </Col>
        <Col xs={12} md={2} lg={2} className='my-4'>
    <Form.Group>
      <NavDropdown 
    title={durationOptions.find(opt => opt.id === selectedDuration)?.name || 'Select Duration'}
    id="duration-dropdown"
    className={`servcies-dropdown dropdown-specialclass-fortwo dropdown-duration ${styles['dropdown-duration-renewal']}`}
    onSelect={(eventKey) => {
        setPageNo(1);
        setTable([]);
        setScrollToDivTop(true);
        if (eventKey === "All") {
            setSelectedDuration("All");
        } else {
            const selectedDurationOption = durationOptions.find(option => option.id === eventKey);
            if (selectedDurationOption) {
                setSelectedDuration(selectedDurationOption.id);
            }
        }
        const durationParam = eventKey.split(" ")[0];
        // Make API call with the updated duration
        GetTableData(
            1, 
            pageSize, 
            "", 
            status, 
            planName, 
            autoRenew, 
            fromDate, 
            toDate, 
            sortCol, 
            sortDir, 
            serviceId, 
            customerId, 
            true,
            false,
            durationParam
        );
    }}
>
     {durationOptions.map(option => (
        <NavDropdown.Item 
            key={option.id}
            eventKey={option.id}
            className={selectedDuration === option.id ? "selected" : ""}
        >
            {option.name}
        </NavDropdown.Item>
    ))}
</NavDropdown>
    </Form.Group>
        </Col>
        <Col xs={12} md={2} lg={2} className='my-4 d-none-mobile'>
                                        <div className='text-right'>
                                            <Button
                                                variant="light"
                                                className="mr-1 px-4 py-0 btn-border-light w-md-auto col-xs-12"
                                                onClick={handleDownloadReport}
                                            >
                                                Download Report
                                            </Button>
                                        </div>
        </Col> 

                                    </>
                                }
                            </Row>
                            </Container>
                            {/* Table Section */}
                            <Row>
                                <Col>
                                    <div className={`table-responsive ${styles['renewal-table']}`} ref={tableWrapperRef}>
                    {initialLoading && (
                     <>
        {serverErrorCustomer && (
            <div className={`${styles['no-data-customers']}`}>
                <img src={EmptyDataTableFilterSearch} className="empty-customers-table-svg" />
                <h2 className="mt-4 mb-3">{GenericServerError}</h2>
            </div>
        )}
        {emptyCustomerSearch && !serverErrorCustomer && (
            <div className={`${styles['no-data-customers']}`}>
                <img src={EmptyDataTableFilterSearch} className="empty-customers-table-svg" />
                <h2 className="mt-4 mb-3">{GenericEmptyMatchingSubscription}</h2>
            </div>
        )}
        {emptyCustomerFilters && !emptyCustomerSearch && !serverErrorCustomer && (
            <div className={`${styles['no-data-customers']}`}>
                <img src={EmptyDataTableFilterSearch} className="empty-customers-table-svg" />
                <h2 className="mt-4 mb-3">{GenericEmptySubscriptionError}</h2>
            </div>
        )}
        {emptyCustomer && !emptyCustomerSearch && !emptyCustomerFilters && !serverErrorCustomer && (
            <div className={`${styles['no-data-customers']}`}>
                <img src={RaiseTicketIllustration} className="empty-customers-table-svg" />
                <h2 className="mt-4 mb-3">{GenericEmptySubscriptionError}</h2>
            </div>
        )}
                     </>
                     )}

                                        {/* Data Table */}
                                        {!serverErrorCustomer && !emptyCustomerFilters && !emptyCustomer && !emptyCustomerSearch &&
                                            <LazyTable
                                                d={columns}
                                                table={table}
                                                customStyles={customStyles}
                                                loading={infinityLoading}
                                                pageNo={pageNo}
                                                totalRows={totalRows}
                                                handlePerRowsChange={handlePerRowsChange}
                                                handlePageChange={handlePageChange}
                                                styles={styles}
                                                handleLazyCalls={handleLazyCalls}
                                                serverErrorUser={serverErrorCustomer}
                                                emptyUser={emptyCustomer}
                                                emptyUserFilters={emptyCustomerFilters}
                                                emptyUserSearch={emptyCustomerSearch}
                                                setScrollToDivTop={setScrollToDivTop}
                                                scrollToDivTop={scrollToDivTop}
                                            />
                                        }
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </Col>
                </div>
                </div>
                </div>}
                {
                  isDisabledRenewalSettings && <AccessDenied />
                }
            </div>
        </>
    )
});

export default RenewalSettingsTab