import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Container, Row, Form, OverlayTrigger, Tooltip, Accordion } from 'react-bootstrap';
import { XLg } from 'react-bootstrap-icons';
import Popup from 'reactjs-popup';
import styles from './CSPRenewalSettings.module.scss';
import { api } from '../../../../../../../Utils/API';
import { CSP_RENEWAL_DELETE_POST, CSP_RENEWAL_SETTINGS_GET, CSP_RENEWAL_CANCEL_POST, CSP_RENEWAL_COTERM } from '../../../../../../../Utils/GlobalConstants';
import Table from 'react-bootstrap/Table';
import EmptyDataTableFilterSearch from '../../../../../../../Assets/Images/Illustrations/EmptyDataTableFilterSearch.svg'
import loadingGif from "../../../../../../../Assets/Images/Animations/loading-management-console.gif"
import CustomNumberSimpleInputDefaultValueReset from '../../../../../ManagementConsole/ServiceManagement/CSP/LocalComponents/Actions/CSPRenewalSettings/LocalComponents';
import { TEXT_SECONDARY } from '../../../../../../../Utils/ColorsConfiguration';
import { GenericEmptyStateError, GenericServerError } from '../../../../../../../Utils/StaticMessages/GenericErrorMessages';
import { AutoRenewErrorToast, DeleteScheduleRenewalErrorToast, DeleteScheduleRenewalSuccessToast, ManageRenewalSuccessToast } from '../../../../../../../Utils/StaticMessages/ToastMessages';
import { CspRenewalSettingNote } from '../../../../../../../Utils/StaticMessages/NotesDisclaimers';

const CSPRenewalSettingsPage = ({ subscriptionTableAPIData, subscriptionId, setSubscriptionTableAPIData, renewalSettingsActionPopupModalOpen, setRenewalSettingsActionPopupModalOpen,
  setToastSuccess, setToastError, setToastMessage, isNcePlan, setIsNcePlan, Logout, actionsSelectedRow, setRefershFlagAction
}) => {

  const [confirmRenewalSettingsLoadingFlag, setConfirmRenewalSettingsLoadingFlag] = useState(false);                                             // loading check in popup
  let ColumnNameCancel = ["Plan", "Commitment Term (Billing Frequency)", "Reseller Price (Ex GST)", "Quantity", "Auto Renew"];                                          // Columns of table for Cancel renewal plan  
  const [selectedOption, setSelectedOption] = useState(null);                                                                                    // selcted tab whether manage ,cancel renewal is selected in tab at the top                                                                // Selecting tab ie. suspend subscription,cancel subscription or re-activate subscription
  const [newQuantity, setNewQuantity] = useState(null);                                                                                          // new edit quantity change
  const [billingCommitmentTermData, setBillingCommitmentTermData] = useState(null);                                                              // data in billing-commitment term dropdown in manage renewal
  const [planNameData, setPlanNameData] = useState(null);                                                                                        // data in planName term dropdown  in manage renewal
  const [showDropdown, setShowDropdown] = useState("initial");                                                                                   // flag to tell whether editing dropdowns should be shown or not
  const [dropdownPlanName, setDropdownPlanName] = useState(null);                                                                                // selected dropdown data in Plan dropdown
  const [dropdownBillingCommitmentTerm, setDropdownBillingCommitmentTerm] = useState(null);                                                      // selected dropdown data in billing commitment term dropdown
  const [confirmRenewalSettingsDeleteFlag, setConfirmRenewalSettingsDeleteFlag] = useState(false);                                               // confirmation for delete renewal 
  const [confirmRenewalSettingsCancelFlag, setConfirmRenewalSettingsCancelFlag] = useState(false);                                               // confirmation popup for start auto renew and cancel auto renew
  const [butttonFlag, setButtonFlag] = useState("");                                                                                             // button flag which tell which buttons should display when edit renewal when it is clicked
  const [emptyDataError, setEmptyDataError] = useState(false);                                                                                   // to check empty data error-204
  const [serverError, setServerError] = useState(false);                                                                                         // to check server error-400
  const [quantityResetFlag, setQuantityResetFlag] = useState(false);
  const [scheduledBillingCommitmentTerm, setScheduledBillingCommitmentTerm] = useState(null);                                                    // to set value of scheduled billing commitment term
  const [scheduledPlanName, setScheduledPlanName] = useState(null);                                                                              // to set value of scheduled plan name
  const [scheduledQuantity, setScheduledQuantity] = useState(null);                                                                              // to set value of scheduled quantity
  const [isScheduledRenewal, setIsScheduledRenewal] = useState(false);                                                                           // to check whether renewal is scheduled or not
  const [deleteInitiated, setDeleteInitiated] = useState(false);                                                                                 // to check whether delete renewal is clicked or not
  const [errorTrialState, setErrorTrialState] = useState(false);

  //CO-TERM
  const [cotermSelectedOption, setCotermSelectedOption] = useState(null);                                                                        // coterm date which is chosen from the list
  const [cotermSelectedOptionId, setCotermSelectedOptionId] = useState(null);                                                                    // for checking the default end date for the plan which is already renewed
  const [cotermDataCalenderMonthFlagMonthly, setCotermDataCalenderMonthFlagMonthly] = useState(false);                                            // store data for calender month date flag
  const [cotermDataCalenderMonthFlagYearly, setCotermDataCalenderMonthFlagYearly] = useState(false);                                              // store data for calender month date flag
  const [defaultCotermDataCalenderMonthFlagMonthly, setDefaultCotermDataCalenderMonthFlagMonthly] = useState(false);                                              // store data for calender month date flag
  const [defaultCotermDataCalenderMonthFlagYearly, setDefaultCotermDataCalenderMonthFlagYearly] = useState(false);                                              // store data for calender month date flag

  const [cotermData, setCotermData] = useState(null);                                                                                            // storing the coterm data from its api
  const [expandedRow, setExpandedRow] = useState(null);                                                                                          // to expand the given date
  let ColumnNameCoterm = ["Plan Name", "Subscription ID ", "Commitment Term (Billing Frequency)"];                                                                      // Columns for table
  const [cotermChosen, setCotermChosen] = useState(null);                                                                                        // display name for opening expandable
  const [alignedEndDate, setAlignedEndDate] = useState(null);                                                                                    // if aligned end is already there ie. if plan is already scheduled for renewal.
  const [alignedEndDateSelection, setAlignedEndDateSelection] = useState("");                                                                    // to distinguish whether it is a month aligned date or normal date selection
  const [defaultAlignedEndDate, setDefaultAlignedEndDate] = useState(null);                                                                      // this is used for checking button conditions based on aligned dates while editing renewal.                                              
  const [cotermCommitmentTerm, setCotermCommitmentTerm] = useState(null);                                                                        // setting the coterm selected by user
  const [defaultCotermCommitmentTerm, setDefaultCotermCommitmentTerm] = useState(null);      
  // Add new state for combined API error tracking
  const [hasCotermApiError, setHasCotermApiError] = useState(false); 
  const [hasManageApiError, setHasManageApiError] = useState(false);
  // for expanding the dates in coterm
  const toggleRow = (index) => {
    if (expandedRow === index) {
      setExpandedRow(null); // Collapse if already expanded
    } else {
      setExpandedRow(index); // Expand the clicked row
    }
  };

  //coterm date selection 
  const handleOptionCotermChange = (value) => {
    setCotermSelectedOption(value);
  };

  // icon direction while expanding the date in coterm
  const arrowDirection = (index) => (expandedRow === index ? 'up' : 'down');

  //selecting tab option at the top : manage renewal or cancel auto renew
  const handleOptionChange = (value) => {
    {
      !subscriptionTableAPIData &&
        <div className={styles["loading-plans"]}><img className="management-console-loading-icon" src={loadingGif} /><p className="text-medium text-strong management-console-loading-text">Loading. . .</p></div>
    }
    {
      subscriptionTableAPIData &&
        setSelectedOption(value);
    }
  };

  // Header Authorization for General API's
  const config = {
    headers: {
      "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
    },
  };

  //get api for renewal settings when auto renew is on for getting planname and billing commitment term in dropdown when editing : NCE PLAN 
  async function GetRenewalSettingsDropdownData(subscriptionId) {
    (subscriptionId === undefined || subscriptionId === null) ? subscriptionId = "" : subscriptionId = subscriptionId;
    setHasManageApiError(false);
    setServerError(false);
    await api.get(`${CSP_RENEWAL_SETTINGS_GET}?subscriptionId=${subscriptionId}`, config)
      .then(resp => {
        if (resp.status == 204) {
          setEmptyDataError(true);
        }
        // handle success
        else {
          setEmptyDataError(false);
          setServerError(false);
          setHasManageApiError(false);
          setBillingCommitmentTermData(resp.data.billingTerms);
          setPlanNameData(resp.data.plans);
          setScheduledBillingCommitmentTerm(resp.data.scheduledBillingTerm);
          setScheduledPlanName(resp.data.scheduledPlanName);
          if (resp.data.alignedEndDate == "" || resp.data.alignedEndDate == null) {
            setAlignedEndDate(null);
            setDefaultAlignedEndDate(null);
          }
          else {
            setAlignedEndDate(resp.data.alignedEndDate);
            setDefaultAlignedEndDate(resp.data.alignedEndDate);
          }
          setScheduledQuantity(resp.data.quantity);
          setIsScheduledRenewal(resp.data.isScheduledRenewal)
          if (resp.data.isScheduledRenewal) {
            setShowDropdown("edit not allowed");
            setDropdownBillingCommitmentTerm(resp.data.scheduledBillingTerm);
            setDropdownPlanName(resp.data.scheduledPlanName);
            if (resp.data.alignedEndDate == "" || resp.data.alignedEndDate == null) {
              setCotermSelectedOption(null);
            }
            else {
              setCotermSelectedOption(resp.data.alignedEndDate);
            }
          }
          else {
            setShowDropdown("edit allowed");
          }
        }
      })
      .catch(error => {
        if (error?.response?.status === 500) {
          setServerError(true);
          setShowDropdown("error");
          setHasManageApiError(true);
        }else if (error.response.status == 400) {
          setServerError(true);
        }
        else if (error?.status == 401 || error?.response?.status == 401) {
          Logout();
        }
      });
  };

  // get co-term 
  async function GetCotermData(subscriptionId) {
    setHasCotermApiError(false);
    setServerError(false);
    await api.get(`${CSP_RENEWAL_COTERM}?subscriptionId=${subscriptionId}`, config)
      .then(resp => {
        if (resp.status == 204) { }
        // handle success
        else {
          setCotermData(resp.data);
          setHasCotermApiError(false);
          setCotermDataCalenderMonthFlagMonthly(resp.data.monthly.alignedMonthEndDateFlag);
          setCotermDataCalenderMonthFlagYearly(resp.data.yearly.alignedMonthEndDateFlag);
          setDefaultCotermDataCalenderMonthFlagMonthly(resp.data.monthly.alignedMonthEndDateFlag);
          setDefaultCotermDataCalenderMonthFlagYearly(resp.data.yearly.alignedMonthEndDateFlag)
        }
      })
      .catch(error => {
        if (error?.response?.status === 500) {
          setServerError(true);
          setShowDropdown("error");
          setHasCotermApiError(true);
        }else if (error.response.status == 400) {
          setServerError(true);
        }
        else if (error?.status == 401 || error?.response?.status == 401) {
          Logout();
        }
      });
  };

  //post api for plan renewal
  async function CSPPostPlanRenewal() {
    setConfirmRenewalSettingsLoadingFlag(true);
    const data = {
      "newQuantity": newQuantity,
      "subscriptionId": subscriptionId,
      "quantity": subscriptionTableAPIData.quantity,
      "planName": dropdownPlanName,
      "billingTerm": dropdownBillingCommitmentTerm == "Trial" ? billingCommitmentTermData[0].name : dropdownBillingCommitmentTerm,
      "alignedDate": cotermSelectedOption,
      "isAlignedMonthEndDate": alignedEndDateSelection == 'calendar-month-selection' ? true : false
    };

    const configFile = {
      headers: {
        "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
        "Content-Type": "application/json",
      },
    };
    await api.post(CSP_RENEWAL_SETTINGS_GET, data, configFile)
      .then(resp => {
        if (resp.status = 200) {
          setConfirmRenewalSettingsLoadingFlag(false);
          setRenewalSettingsActionPopupModalOpen(false);
          setToastMessage(ManageRenewalSuccessToast);
          setToastSuccess(true);
          setSelectedOption(null);
          setSubscriptionTableAPIData(null);
          setRefershFlagAction(true);
          setTimeout(() => {
            setToastSuccess(false);
          }, 5000);
        }
      })
      .catch(error => {
        setConfirmRenewalSettingsLoadingFlag(false);
        if (error?.status == 401 || error?.response?.status == 401) {
          Logout();
        }
        setToastMessage(error.response.data.errors);
        setToastError(true);
        setTimeout(() => {
          setToastError(false);
        }, 5000);

      })
  };

  //post api for delete renewal
  async function CSPDeleteRenewal(subscriptionId) {
    setConfirmRenewalSettingsLoadingFlag(true);
    await api.post(`${CSP_RENEWAL_DELETE_POST}?subscriptionId=${subscriptionId}`, null, config)
      .then(resp => {
        if (resp.status = 200) {
          setConfirmRenewalSettingsLoadingFlag(false);
          setRenewalSettingsActionPopupModalOpen(false);
          setToastMessage(DeleteScheduleRenewalSuccessToast);
          setToastSuccess(true);
          setSelectedOption(null);
          setDeleteInitiated(false);
          setRefershFlagAction(true);
          setTimeout(() => {
            setToastSuccess(false);
          }, 5000);
        }
      })
      .catch(error => {
        setConfirmRenewalSettingsLoadingFlag(false);
        if (error?.status == 401 || error?.response?.status == 401) {
          Logout();
        }
        setToastMessage(DeleteScheduleRenewalErrorToast);
        setToastError(true);
        setTimeout(() => {
          setToastError(false);
        }, 5000);

      })
  };

  //post api for cancel/start auto renewal
  async function CSPPostStartCancelAutoRenew() {
    setConfirmRenewalSettingsLoadingFlag(true);

    const data = {
      "subscriptionId": subscriptionId,
      "quantity": subscriptionTableAPIData.quantity,
      "autoRenew": !subscriptionTableAPIData.autoRenew
    };

    const configFile = {
      headers: {
        "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
        "Content-Type": "application/json",
      },
    };
    await api.post(CSP_RENEWAL_CANCEL_POST, data, configFile)
      .then(resp => {
        if (resp.status = 200) {
          setConfirmRenewalSettingsLoadingFlag(false);
          setRenewalSettingsActionPopupModalOpen(false);
          setToastMessage(subscriptionTableAPIData && (subscriptionTableAPIData.autoRenew ? "Auto-Renew Cancelled Successfully" : "Auto-renew turned on Successfully."));
          setToastSuccess(true);
          setSelectedOption(null);
          setSubscriptionTableAPIData(null);
          setRefershFlagAction(true);
          setTimeout(() => {
            setToastSuccess(false);
          }, 5000);
        }
      })
      .catch(error => {
        setConfirmRenewalSettingsLoadingFlag(false);
        if (error?.status == 401 || error?.response?.status == 401) {
          Logout();
        }
        setToastMessage(AutoRenewErrorToast);
        setToastError(true);
        setTimeout(() => {
          setToastError(false);
        }, 5000);
      })
  };

  // Use Effect to get all the billing-commitment term, plan name in dropdown
  useEffect(() => {
    if (subscriptionTableAPIData) {
      // for passing commitment term in coterm api
      const str = subscriptionTableAPIData.billingTerm;
      const extracted = str.split('(')[0].trim();
      // Convert all letters of extracted to lowercase
      const modifiedExtracted = extracted?.toLowerCase();
      setCotermCommitmentTerm(modifiedExtracted);
      setDefaultCotermCommitmentTerm(modifiedExtracted);
      GetRenewalSettingsDropdownData(subscriptionId);
      GetCotermData(subscriptionId);
      setDropdownBillingCommitmentTerm(subscriptionTableAPIData.billingTerm);
      setDropdownPlanName(subscriptionTableAPIData.planName);
    }
  }, [subscriptionTableAPIData]);

  // for displaying default table when popup is opened
  useEffect(() => {
    if (subscriptionTableAPIData && subscriptionTableAPIData.autoRenew) {
      setSelectedOption('manage');
    }
    else {
      setSelectedOption('');
    }
  }, [subscriptionTableAPIData]);

  return (
    <div>
      {/* confirmation popup for delete */}
      <Popup
        className="custom-modal custom-modal-md"
        open={confirmRenewalSettingsDeleteFlag}
        onClose={() => { setConfirmRenewalSettingsDeleteFlag(false);}}
        modal
        nested
      >
        {(close) => (
          <div className="modal">
            <div className="header p-4 py-3">
              <h2 className="mb-0 px-3">Renewal Settings</h2>
              <XLg
                size={18}
                className="cpointer text-strong close-icon-popup-sm"
                onClick={close}
                color={TEXT_SECONDARY}
              />
            </div>
            <Container className="p-5">
              <p className={`pt-0 px-3 pb-5 mb-0 ${styles['renewal-setting-gws-confirmation-line']}`}>
                <strong>Are you sure you want to delete the Scheduled Renewal?</strong>
              </p>
              <div className="actions">
                <div className="d-flex justify-content-center pb-3 pt-2">
                  <Button
                    className="btn btn-lg mx-3 btn-border-light"
                    variant="light"
                    onClick={() => {
                      close();
                    }}
                  >
                    No
                  </Button>
                  <Button
                    className="btn btn-md"
                    onClick={() => {
                      CSPDeleteRenewal(subscriptionId);
                      setDeleteInitiated(true);
                      close();
                    }}
                  >
                    Yes
                  </Button>
                </div>
              </div>
            </Container>
          </div>
        )}
      </Popup>

      {/* confirmation popup for cancel or start auto renew  */}
      <Popup
        className="custom-modal custom-modal-md"
        open={confirmRenewalSettingsCancelFlag}
        onClose={() => { setConfirmRenewalSettingsCancelFlag(false);}}
        modal
        nested
      >
        {(close) => (
          <div className="modal">
            <div className="header p-4 py-3">
              <h2 className="mb-0 px-3">Renewal Settings</h2>
              <XLg
                size={18}
                className="cpointer text-strong close-icon-popup-sm"
                onClick={close}
                color={TEXT_SECONDARY}
              />
            </div>
            <Container className="p-5">
              <p className={`pt-0 px-3 pb-5 mb-0 ${styles['renewal-setting-gws-confirmation-line']}`}>
                <strong>{subscriptionTableAPIData && subscriptionTableAPIData.autoRenew ? ("Are you sure you want to Turn Off Auto - Renewal?") : ("Are you sure you want to start Auto - Renewal?")}</strong>
              </p>
              <div className="actions">
                <div className="d-flex justify-content-center pb-3 pt-2">
                  <Button
                    className="btn btn-lg mx-3 btn-border-light"
                    variant="light"
                    onClick={() => {
                      close();
                    }}
                  >
                    No
                  </Button>
                  <Button
                    className="btn btn-md"
                    onClick={() => {
                      CSPPostStartCancelAutoRenew();
                      close(); 
                    }}
                  >
                    Yes
                  </Button>
                </div>
              </div>
            </Container>
          </div>
        )}
      </Popup>

      {/* Renewal settings Popup */}
      <Popup
        className="custom-modal custom-modal-xxl"
        open={renewalSettingsActionPopupModalOpen}
        onClose={() => { setRenewalSettingsActionPopupModalOpen(false); setSubscriptionTableAPIData(null); setShowDropdown("initial"); setButtonFlag(""); setIsNcePlan(null); setEmptyDataError(false); setServerError(false); setErrorTrialState(false);
          setHasCotermApiError(false);
          setHasManageApiError(false);
        }}
        modal
        closeOnDocumentClick={!confirmRenewalSettingsLoadingFlag}
        nested
      >
        {(close) => (
          <div className="modal">
            <>
              <div className="header">
                <h2>Renewal Settings</h2>
                <XLg
                  size={18}
                  className="cpointer text-strong"
                  onClick={() => { if (!confirmRenewalSettingsLoadingFlag) close() }}
                  color={TEXT_SECONDARY}
                />
              </div>
              <Container fluid>
                    <Card className={`${styles['accordion-downgradeGWS']} mt-4`}>
                        <Row className={`ml-1 mr-1 ${styles['tab-header']}`}>
                            <Col md={5} className="mt-2 mb-2">
                                <span className={`${styles['tab-header']}`} ><span className={`text-strong ${styles['tab-header']}`}>Customer :</span> {actionsSelectedRow ? actionsSelectedRow.customerName : "Loading"}</span>
                            </Col>
                            <Col md={7} className="mt-2 mb-2">
                                <span className={`${styles['tab-header']}`} ><span className={`text-strong ${styles['tab-header']}`}>Current Subscription ID :</span> {subscriptionTableAPIData ? subscriptionTableAPIData.vendorSubscriptionId : "Loading"}</span>
                            </Col>
                        </Row>
                    </Card>
                <div className='mt-4 mb-4'>
                  <Row>
                    {/* tabs for NCE PLAN */}
                    {/* when processing button is not there */}
                    {!confirmRenewalSettingsLoadingFlag && isNcePlan &&
                      <div>
                        {/* 1st button tab: Manage Renewal when auto renew is true*/}
                        {subscriptionTableAPIData && subscriptionTableAPIData.autoRenew &&
                          <label className='text-strong mr-5' >
                            <input
                              className='mr-2'
                              type="radio"
                              name="subscriptionOption"
                              value="manage"
                              style={{ verticalAlign: 'top' }}
                              checked={selectedOption === 'manage'}
                              onChange={() => handleOptionChange('manage')}
                            />
                            Manage Renewal
                          </label>
                        }

                        {/* 2nd button tab: Cancel Renewal when auto renew is true*/}
                        {subscriptionTableAPIData && subscriptionTableAPIData.autoRenew &&
                          <label className='text-strong'>
                            <input
                              className='mr-2'
                              type="radio"
                              name="subscriptionOption"
                              value="cancel"
                              style={{ verticalAlign: 'top' }}
                              checked={selectedOption === 'cancel'}
                              onChange={() => handleOptionChange('cancel')}
                            />
                            Turn Off Auto - Renewal </label>
                        }
                      </div>
                    }
                    {/* when processing button is there */}
                    {confirmRenewalSettingsLoadingFlag && isNcePlan &&
                      <div>
                        {/* 1st button tab: Manage Renewal when auto renew is true*/}
                        {subscriptionTableAPIData && subscriptionTableAPIData.autoRenew &&
                          <label className='text-strong mr-5' >
                            <input
                              className='mr-2'
                              type="radio"
                              name="subscriptionOption"
                              value="manage"
                              style={{ verticalAlign: 'top' }}
                              checked={selectedOption === 'manage'}
                              disabled
                            />
                            Manage Renewal
                          </label>
                        }

                        {/* 2nd button tab: Cancel Renewal when auto renew is true*/}
                        {subscriptionTableAPIData && subscriptionTableAPIData.autoRenew &&
                          <label className='text-strong'>
                            <input
                              className='mr-2'
                              type="radio"
                              name="subscriptionOption"
                              value="cancel"
                              style={{ verticalAlign: 'top' }}
                              checked={selectedOption === 'cancel'}
                              disabled
                            />
                            Turn Off Auto - Renewal </label>
                        }
                      </div>
                    }

                    {/* heading: Cancel Renewal when auto renew is false in legacy*/}
                    {subscriptionTableAPIData && !isNcePlan && subscriptionTableAPIData.autoRenew &&
                      <Col md={4}>
                        <label className='text-strong text-regular'>Turn Off Auto - Renewal</label>
                      </Col>}

                    {/* heading: Start Auto Renewal when auto renew is false*/}
                    {subscriptionTableAPIData && !subscriptionTableAPIData.autoRenew &&
                      <Col md={4}>
                        <label className='text-strong text-regular'>Start Auto - Renewal</label>
                      </Col>}

                  </Row>

                  {/* manage renewal in nce plan */}
                  {subscriptionTableAPIData && selectedOption === 'manage' && isNcePlan &&
                    <Row className='mt-4'>
                      <Col md={3}>
                        <Card className={`${styles['renewal-settings-card']}`}>
                          <div className='ml-5 mt-3 mr-3'>
                            <Row className='mb-4'>
                              <Col><p className='text-muted'>Current Plan</p>
                                <p className='text-wrap '
                                  style={{ whiteSpace: 'nowrap' }}
                                >{subscriptionTableAPIData.planName}</p>
                              </Col>
                            </Row>
                            <Row className='mb-4'>
                              <Col><p className='text-muted '>Quantity</p>
                                <p>{subscriptionTableAPIData.quantity}</p></Col>
                            </Row>
                            <Row className='mb-4'>
                              <Col><p className='text-muted'>Commitment Term (Billing Frequency):</p>
                                <p>{subscriptionTableAPIData.billingTerm}</p>
                              </Col>
                            </Row>
                          </div>
                        </Card>
                      </Col>
                      {!serverError && !emptyDataError && !hasCotermApiError && !hasManageApiError &&
                        <Col md={9}>
                          {showDropdown !== "edit not allowed" &&
                            <Card className={`${styles['renewal-settings-card']}`}>
                              {showDropdown === "initial" &&
                                <div className={styles["loading"]}><img className="management-console-loading-icon" src={loadingGif} /><p className="text-medium text-strong management-console-loading-text">Loading. . .</p></div>
                              }

                              {/* to show dropdown in billing commitment term and plan name ie. editing field if schedulerenewal is false*/}
                              {showDropdown === "edit allowed" && !isScheduledRenewal &&
                                <div>
                                  <div className='ml-6 mt-3 mr-3 mb-2'>
                                    <Row>
                                      <Col md={7}>
                                        <Col md={12} className='mb-3'><div className='text-muted text-medium'>Plan</div>
                                            <div>
                                            <Form.Select
                                                className={dropdownPlanName === subscriptionTableAPIData.planName ? "pt-3 pb-2" : `pt-3 pb-2 ${styles['dropdown-selected']}`
                                                }
                                                size="lg"
                                                value={dropdownPlanName}
                                                id='csp-subscription-type-id'
                                                aria-label="Default select example"
                                                onChange={(e) => {
                                                setDropdownPlanName(e.target.value);
                                                setQuantityResetFlag(true);
                                                }}
                                            >
                                                {planNameData &&
                                                planNameData.map((dropdownItem) => (
                                                    <option key={dropdownItem.id} value={dropdownItem.id}>
                                                    {dropdownItem.planName}
                                                    </option>
                                                ))
                                                }
                                            </Form.Select>
                                            </div>
                                        </Col>
                                        <Col md={12} className='mb-3'>
                                        <div className='text-muted text-medium'>Quantity</div>
                                        {planNameData &&
                                          planNameData
                                            .filter(item => item.planName === dropdownPlanName)
                                            .map((items) => (
                                              <CustomNumberSimpleInputDefaultValueReset
                                                defaultValue={subscriptionTableAPIData.quantity}
                                                min={items.minQuantity}
                                                max={items.maxQuantity}
                                                setUpdatedQuanity={setNewQuantity}
                                                refreshFlag={quantityResetFlag}
                                                setRefreshFlag={setQuantityResetFlag}
                                              />
                                            ))
                                        }
                                        </Col>
                                        <Col md={12} className='mb-3'>
                                        <div className='text-muted text-medium'>Commitment Term (Billing Frequency):</div>
                                        <div>
                                          <Form.Select
                                            className={dropdownBillingCommitmentTerm === subscriptionTableAPIData.billingTerm ? "pt-3 pb-2" : `pt-3 pb-2 ${styles['dropdown-selected']}`
                                            }
                                            // 'pt-3 pb-2'
                                            value={dropdownBillingCommitmentTerm}
                                            id='csp-subscription-type-id'
                                            aria-label="Default select example"
                                            onChange={(e) => {
                                              setDropdownBillingCommitmentTerm(e.target.value);
                                              const str = e.target.value;
                                              const extracted = str.split('(')[0].trim();
                                              // Convert all letters of extracted to lowercase
                                              const modifiedExtracted = extracted?.toLowerCase();
                                              setCotermCommitmentTerm(modifiedExtracted);
                                              setCotermSelectedOptionId(null);
                                              setAlignedEndDateSelection('');
                                              setCotermSelectedOption(null);
                                              setExpandedRow(null);
                                            }}
                                          >
                                            {billingCommitmentTermData &&
                                              billingCommitmentTermData.map((dropdownItem) => (
                                                <option key={dropdownItem.id} value={dropdownItem.id}>
                                                  {dropdownItem.name}
                                                </option>
                                              ))
                                            }
                                          </Form.Select>
                                        </div>
                                      </Col>
                                      </Col>
                                      <Col md={5} className='pl-8'>
                                      <div className='text-muted text-medium mb-2'>End Date Alignment</div>
                                        <div className={`ml-3 mt-0 ${styles['dates-coterm-height']}`}>
                                          {cotermData && (
                                            <Form>
                                              {cotermCommitmentTerm === "monthly" ? (
                                                <>
                                                  {cotermData.monthly?.cotermSubscriptions.map((option, index) => (
                                                    <div key={index}>
                                                      <div className='mr-5'>
                                                        <input
                                                          className='mr-2'
                                                          type="radio"
                                                          name="cotermOption"
                                                          style={{ verticalAlign: 'middle' }}
                                                          checked={cotermSelectedOptionId === index}
                                                          onClick={() => {
                                                            if (cotermSelectedOptionId === index) {
                                                              setCotermDataCalenderMonthFlagMonthly(false);
                                                              setCotermSelectedOptionId(null);
                                                              setAlignedEndDateSelection('');
                                                              handleOptionCotermChange(null);
                                                            } else {
                                                              setCotermDataCalenderMonthFlagMonthly(false);
                                                              setCotermSelectedOptionId(index);
                                                              setAlignedEndDateSelection('dates-selection');
                                                              handleOptionCotermChange(option.date); // Pass the selected option
                                                            }
                                                          }}
                                                        />
                                                        <span onClick={() => {
                                                          setCotermChosen(option.date);
                                                          toggleRow(index);
                                                        }}>
                                                          <span className='cpointer'>{option.date}</span>
                                                          <span className={`ml-2 gdap-${arrowDirection(index)}-button`} />
                                                        </span>
                                                        {cotermChosen === option.date && expandedRow !== null && (
                                                          <div className={`mt-3`}>
                                                            <Table responsive="md">
                                                              <thead className='sticky-top'>
                                                                <tr>
                                                                {ColumnNameCoterm.map(th => {
                                                                    return (<th key={th} className={(th === "Commitment Term (Billing Frequency)") ? `${styles['commitment-term-width']}` : "" }>{th}</th>)
                                                                  })}
                                                                </tr>
                                                              </thead>
                                                              <tbody>
                                                                {option.subscriptions.map((item, index) => (
                                                                  <tr key={item.vendorSubscriptionId} className={`${styles['vertical-align-middle']}`}>
                                                                    <td>{item.planName}</td>
                                                                    <td>{item.vendorSubscriptionId}</td>
                                                                    <td>{item.billingTerm}</td>
                                                                  </tr>
                                                                ))}
                                                              </tbody>
                                                            </Table>
                                                          </div>
                                                        )}
                                                      </div>
                                                      <br />
                                                    </div>
                                                  ))}
                                                  {/* Align end date with calendar month */}
                                                  <div className='mb-2'>
                                                    <label className='mr-5'>
                                                      <input
                                                        className='mr-1'
                                                        type="radio"
                                                        name="cotermOption"
                                                        style={{ verticalAlign: 'middle' }}
                                                        checked={cotermSelectedOptionId === "9999" || cotermDataCalenderMonthFlagMonthly}
                                                        onClick={() => {
                                                          if (cotermSelectedOptionId === "9999" || cotermDataCalenderMonthFlagMonthly) {
                                                            // If already selected, then deselect
                                                            setCotermDataCalenderMonthFlagMonthly(false);
                                                            setCotermSelectedOptionId(null);
                                                            setAlignedEndDateSelection('');
                                                            handleOptionCotermChange(null);
                                                          } else {
                                                            // If not selected, then select
                                                            if (defaultCotermDataCalenderMonthFlagMonthly) {
                                                              setCotermDataCalenderMonthFlagMonthly(true);
                                                            }
                                                            setCotermSelectedOptionId("9999");
                                                            setAlignedEndDateSelection('calendar-month-selection');
                                                            handleOptionCotermChange(cotermData.monthly.alignedMonthEndDate);
                                                          }
                                                        }}
                                                      /> Align end date with calendar month ({cotermData.monthly.alignedMonthEndDate})
                                                    </label>
                                                  </div>
                                                </>
                                              ) : (
                                                <>
                                                  {cotermData?.yearly.cotermSubscriptions.map((option, index) => (
                                                    <div key={index}>
                                                      <div className='mr-5'>
                                                        <input
                                                          className='mr-2'
                                                          type="radio"
                                                          name="cotermOption"
                                                          style={{ verticalAlign: 'middle' }}
                                                          checked={cotermSelectedOptionId === index}
                                                          onClick={() => {
                                                            if (cotermSelectedOptionId === index) {
                                                              setCotermDataCalenderMonthFlagYearly(false);
                                                              setCotermSelectedOptionId(null); // Deselect if already selected
                                                              setAlignedEndDateSelection('');
                                                              handleOptionCotermChange(null); // Pass the deselected option
                                                            } else {
                                                              setCotermDataCalenderMonthFlagYearly(false);
                                                              setCotermSelectedOptionId(index);
                                                              setAlignedEndDateSelection('dates-selection');
                                                              handleOptionCotermChange(option.date); // Pass the selected option
                                                            }
                                                          }}
                                                        />
                                                        <span onClick={() => {
                                                          setCotermChosen(option.date);
                                                          toggleRow(index);
                                                        }}>
                                                          <span className='cpointer'>{option.date}</span>
                                                          <span className={`ml-2 gdap-${arrowDirection(index)}-button`} />
                                                        </span>
                                                        {cotermChosen === option.date && expandedRow !== null && (
                                                          <div className={`mt-3`}>
                                                            <Table responsive="md">
                                                              <thead className='sticky-top'>
                                                                <tr>
                                                                {ColumnNameCoterm.map(th => {
                                                                    return (<th key={th} className={(th === "Commitment Term (Billing Frequency)") ? `${styles['commitment-term-width']}` : "" }>{th}</th>)
                                                                  })}
                                                                </tr>
                                                              </thead>
                                                              <tbody>
                                                                {option.subscriptions.map((item, index) => (
                                                                  <tr key={item.vendorSubscriptionId} className={`${styles['vertical-align-middle']}`}>
                                                                    <td>{item.planName}</td>
                                                                    <td>{item.vendorSubscriptionId}</td>
                                                                    <td>{item.billingTerm}</td>
                                                                  </tr>
                                                                ))}
                                                              </tbody>
                                                            </Table>
                                                          </div>
                                                        )}
                                                      </div>
                                                      <br />
                                                    </div>
                                                  ))}
                                                  {/* Align end date with calendar month */}
                                                  <div className='mb-2'>
                                                    <label className='mr-5'>
                                                      <input
                                                        className='mr-1'
                                                        type="radio"
                                                        name="cotermOption"
                                                        style={{ verticalAlign: 'middle' }}
                                                        checked={cotermSelectedOptionId == "9999" || cotermDataCalenderMonthFlagYearly}
                                                        onClick={() => {
                                                          if (cotermSelectedOptionId == "9999" || cotermDataCalenderMonthFlagYearly) {  // If already selected, deselect
                                                            setCotermDataCalenderMonthFlagYearly(false);
                                                            setCotermSelectedOptionId(null); // Deselect if already selected
                                                            setAlignedEndDateSelection('');
                                                            handleOptionCotermChange(null); // Pass the deselected option

                                                          } else {
                                                            if (defaultCotermDataCalenderMonthFlagYearly) {
                                                              setCotermDataCalenderMonthFlagYearly(true);
                                                            }
                                                            setCotermSelectedOptionId("9999"); // Select the radio button
                                                            setAlignedEndDateSelection('calendar-month-selection'); // Set related selection state
                                                            handleOptionCotermChange(cotermData.yearly.alignedMonthEndDate);
                                                          }
                                                        }}
                                                      />
                                                      Align end date with calendar month ({cotermData.yearly.alignedMonthEndDate})
                                                    </label>
                                                  </div>
                                                </>
                                              )}
                                            </Form>
                                          )}
                                          {
                                            !cotermData &&
                                            <div className={styles["loading-plans"]}><img className="management-console-loading-icon" src={loadingGif} /><p className="text-medium text-strong management-console-loading-text">Loading. . .</p></div>
                                          }
                                        </div>
                                      </Col>
                                    </Row>
                                  </div>
                                  <Row className='mb-4'>
                                    <Col md={6} className='notes-confirmation'>
                                      <div className='ml-6 text-small mb-3'><span className='text-extra-strong'>Note :</span> {CspRenewalSettingNote}</div>
                                    </Col>
                                    <Col md={6} className='d-flex align-items-center justify-items-center justify-content-end position-relative'>
                                      {butttonFlag === "" &&
                                        <>
                                          {defaultCotermDataCalenderMonthFlagMonthly || defaultCotermDataCalenderMonthFlagYearly ?
                                            <>   {(dropdownPlanName !== subscriptionTableAPIData.planName || dropdownBillingCommitmentTerm !== subscriptionTableAPIData.billingTerm || newQuantity !== subscriptionTableAPIData.quantity || cotermDataCalenderMonthFlagMonthly != defaultCotermDataCalenderMonthFlagMonthly || cotermDataCalenderMonthFlagYearly != defaultCotermDataCalenderMonthFlagYearly) && !confirmRenewalSettingsLoadingFlag &&
                                              <Button
                                                className="btn btn-lg mx-2 btn-border-light"
                                                variant="light"
                                                onClick={() => {
                                                  setCotermChosen(null);
                                                  setCotermSelectedOption(null);
                                                  setCotermSelectedOptionId(null);
                                                  setExpandedRow(null);
                                                  setQuantityResetFlag(true);
                                                  setDropdownBillingCommitmentTerm(subscriptionTableAPIData.billingTerm);
                                                  setDropdownPlanName(subscriptionTableAPIData.planName);
                                                  setNewQuantity(subscriptionTableAPIData.quantity);
                                                  setCotermCommitmentTerm(defaultCotermCommitmentTerm);
                                                  setCotermDataCalenderMonthFlagMonthly(defaultCotermDataCalenderMonthFlagMonthly);
                                                  setCotermDataCalenderMonthFlagYearly(defaultCotermDataCalenderMonthFlagYearly);
                                                }
                                                }>Clear</Button>}
                                              {(dropdownPlanName === subscriptionTableAPIData.planName && dropdownBillingCommitmentTerm === subscriptionTableAPIData.billingTerm && newQuantity === subscriptionTableAPIData.quantity && cotermDataCalenderMonthFlagMonthly == defaultCotermDataCalenderMonthFlagMonthly && cotermDataCalenderMonthFlagYearly == defaultCotermDataCalenderMonthFlagYearly) && !confirmRenewalSettingsLoadingFlag &&
                                                <Button
                                                  className="btn btn-lg mx-2"
                                                  variant="light"
                                                  disabled
                                                >
                                                  Clear
                                                </Button>}
                                              {(dropdownPlanName !== subscriptionTableAPIData.planName || dropdownBillingCommitmentTerm !== subscriptionTableAPIData.billingTerm || newQuantity !== subscriptionTableAPIData.quantity || cotermDataCalenderMonthFlagMonthly != defaultCotermDataCalenderMonthFlagMonthly || cotermDataCalenderMonthFlagYearly != defaultCotermDataCalenderMonthFlagYearly || subscriptionTableAPIData.billingTerm == "Trial") && !confirmRenewalSettingsLoadingFlag &&
                                                <Button className='mx-3 px-3' onClick={() => CSPPostPlanRenewal()}>Plan Renewal</Button>}

                                              {(dropdownPlanName === subscriptionTableAPIData.planName && dropdownBillingCommitmentTerm === subscriptionTableAPIData.billingTerm && newQuantity === subscriptionTableAPIData.quantity && cotermDataCalenderMonthFlagMonthly == defaultCotermDataCalenderMonthFlagMonthly && cotermDataCalenderMonthFlagYearly == defaultCotermDataCalenderMonthFlagYearly && subscriptionTableAPIData.billingTerm != "Trial") && !confirmRenewalSettingsLoadingFlag &&
                                                <Button
                                                  className="btn btn-lg mx-3 px-3"
                                                  variant="light"
                                                  disabled
                                                >
                                                  Plan Renewal
                                                </Button>}</>
                                            : <>
                                              {(dropdownPlanName !== subscriptionTableAPIData.planName || dropdownBillingCommitmentTerm !== subscriptionTableAPIData.billingTerm || newQuantity !== subscriptionTableAPIData.quantity || cotermSelectedOption != null) && !confirmRenewalSettingsLoadingFlag &&
                                                <Button
                                                  className="btn btn-lg mx-2 btn-border-light"
                                                  variant="light"
                                                  onClick={() => {
                                                    setCotermChosen(null);
                                                    setCotermSelectedOption(null);
                                                    setCotermSelectedOptionId(null);
                                                    setExpandedRow(null);
                                                    setQuantityResetFlag(true);
                                                    setDropdownBillingCommitmentTerm(subscriptionTableAPIData.billingTerm);
                                                    setDropdownPlanName(subscriptionTableAPIData.planName);
                                                    setNewQuantity(subscriptionTableAPIData.quantity);
                                                    setCotermCommitmentTerm(defaultCotermCommitmentTerm);
                                                    setCotermDataCalenderMonthFlagMonthly(defaultCotermDataCalenderMonthFlagMonthly);
                                                    setCotermDataCalenderMonthFlagYearly(defaultCotermDataCalenderMonthFlagYearly);
                                                  }
                                                  }>Clear</Button>}
                                              {(dropdownPlanName === subscriptionTableAPIData.planName && dropdownBillingCommitmentTerm === subscriptionTableAPIData.billingTerm && newQuantity === subscriptionTableAPIData.quantity && cotermSelectedOption == null) && !confirmRenewalSettingsLoadingFlag &&
                                                <Button
                                                  className="btn btn-lg mx-2"
                                                  variant="light"
                                                  disabled
                                                >
                                                  Clear
                                                </Button>}
                                              {(dropdownPlanName !== subscriptionTableAPIData.planName || dropdownBillingCommitmentTerm !== subscriptionTableAPIData.billingTerm || newQuantity !== subscriptionTableAPIData.quantity || cotermSelectedOption != null  || subscriptionTableAPIData.billingTerm == "Trial") && !confirmRenewalSettingsLoadingFlag &&
                                                <Button className='mx-3 px-3' onClick={() => CSPPostPlanRenewal()}>Plan Renewal</Button>}

                                              {(dropdownPlanName === subscriptionTableAPIData.planName && dropdownBillingCommitmentTerm === subscriptionTableAPIData.billingTerm && newQuantity === subscriptionTableAPIData.quantity && cotermSelectedOption == null && subscriptionTableAPIData.billingTerm != "Trial") && !confirmRenewalSettingsLoadingFlag &&
                                                <Button
                                                  className="btn btn-lg mx-3 px-3"
                                                  variant="light"
                                                  disabled
                                                >
                                                  Plan Renewal
                                                </Button>}
                                            </>
                                          }
                                          {confirmRenewalSettingsLoadingFlag && subscriptionTableAPIData && <Button
                                            disabled
                                            className="btn btn-lg mx-2"
                                            variant="light"
                                          >
                                            Clear</Button>}
                                          {confirmRenewalSettingsLoadingFlag && subscriptionTableAPIData && <Button
                                            disabled
                                            className="btn btn-lg mx-3 px-2"
                                            variant="light"
                                          >
                                            Processing . . .
                                          </Button>}
                                        </>}

                                      {butttonFlag === "edit renewal buttons" &&
                                        <>
                                          {!confirmRenewalSettingsLoadingFlag && <Button className="btn btn-lg mx-2 btn-border-light"
                                            variant="light" onClick={() => {
                                              setShowDropdown("edit not allowed");
                                              setDropdownBillingCommitmentTerm(subscriptionTableAPIData.billingTerm);
                                              setDropdownPlanName(subscriptionTableAPIData.planName);
                                              setNewQuantity(subscriptionTableAPIData.quantity);
                                            }}>
                                            Back</Button>}
                                          {(dropdownPlanName === subscriptionTableAPIData.planName && dropdownBillingCommitmentTerm === subscriptionTableAPIData.billingTerm && newQuantity === subscriptionTableAPIData.quantity) && !confirmRenewalSettingsLoadingFlag &&
                                            <Button className="btn btn-lg mx-3"
                                              variant="light"
                                              disabled>Save</Button>}
                                          {(dropdownPlanName !== subscriptionTableAPIData.planName || dropdownBillingCommitmentTerm !== subscriptionTableAPIData.billingTerm || newQuantity !== subscriptionTableAPIData.quantity) && !confirmRenewalSettingsLoadingFlag &&
                                            <Button className="mr-3" onClick={() => CSPPostPlanRenewal()}>Save</Button>}
                                          {confirmRenewalSettingsLoadingFlag && subscriptionTableAPIData && <Button
                                            disabled
                                            className="btn btn-lg mx-2 btn-border-light"
                                            variant="light"
                                          >
                                            Back
                                          </Button>}
                                          {confirmRenewalSettingsLoadingFlag && subscriptionTableAPIData && <Button
                                            disabled
                                            className="ml-3 btn-border-light w-md-auto col-xs-12 mr-1"
                                            variant="light"
                                          >
                                            Processing . . .
                                          </Button>}
                                        </>}
                                    </Col>
                                  </Row>
                                </div>}

                              {/* to show dropdown in billing commitment term and plan name ie. editing field if schedulerenewal is true*/}
                              {showDropdown === "edit allowed" && isScheduledRenewal &&
                                <div>
                                  <div className='ml-6 mt-3 mr-3'>
                                    <Row>
                                      <Col md={7}><div className='text-muted text-medium'>Plan</div>
                                        <div>
                                          <Form.Select
                                            className={dropdownPlanName === scheduledPlanName ? "pt-3 pb-2" : `pt-3 pb-2 ${styles['dropdown-selected']}`
                                            }
                                            size="lg"
                                            value={dropdownPlanName}
                                            id='csp-subscription-type-id'
                                            aria-label="Default select example"
                                            onChange={(e) => {
                                              setDropdownPlanName(e.target.value);
                                              setQuantityResetFlag(true);
                                            }}
                                          >
                                            {planNameData &&
                                              planNameData.map((dropdownItem) => (
                                                <option key={dropdownItem.id} value={dropdownItem.id}>
                                                  {dropdownItem.planName}
                                                </option>
                                              ))
                                            }
                                          </Form.Select>

                                        </div>
                                      </Col>
                                      <Col md={5} className='pl-8'><div className='text-muted text-medium'>Quantity</div>
                                        {planNameData &&
                                          planNameData
                                            .filter(item => item.planName === dropdownPlanName)
                                            .map((items) => (
                                              <CustomNumberSimpleInputDefaultValueReset
                                                defaultValue={scheduledQuantity}
                                                min={items.minQuantity}
                                                max={items.maxQuantity}
                                                setUpdatedQuanity={setNewQuantity}
                                                refreshFlag={quantityResetFlag}
                                                setRefreshFlag={setQuantityResetFlag}
                                              />
                                            ))
                                        }
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col md={7}><div className='text-muted text-medium'>Commitment Term (Billing Frequency):</div>
                                        <div>
                                          <Form.Select
                                            className={dropdownBillingCommitmentTerm === scheduledBillingCommitmentTerm ? "pt-3 pb-2" : (errorTrialState ? `border-danger pt-3 pb-2 ${styles['dropdown-selected']}` : `pt-3 pb-2 ${styles['dropdown-selected']}`)
                                            }
                                            value={dropdownBillingCommitmentTerm}
                                            id='csp-subscription-type-id'
                                            aria-label="Default select example"
                                            onChange={(e) => {
                                              // console.log("vvv:", scheduledBillingCommitmentTerm, e.target.value, scheduledBillingCommitmentTerm == e.target.value)
                                              setErrorTrialState(false);
                                              setDropdownBillingCommitmentTerm(e.target.value);
                                              const str = e.target.value;
                                              const extracted = str.split('(')[0].trim();
                                              // Convert all letters of extracted to lowercase
                                              const modifiedExtracted = extracted?.toLowerCase();
                                              if (e.target.value === scheduledBillingCommitmentTerm) {
                                                setAlignedEndDate(defaultAlignedEndDate);
                                                setCotermCommitmentTerm(modifiedExtracted);
                                                setCotermSelectedOption(defaultAlignedEndDate);
                                              } else {
                                                setCotermCommitmentTerm(modifiedExtracted);

                                                if (e.target.value === scheduledBillingCommitmentTerm) {
                                                  setAlignedEndDate(defaultAlignedEndDate);
                                                  setCotermSelectedOption(defaultAlignedEndDate);
                                                  // setCotermSelectedOptionId(defaultCotermSelectedOptionId);
                                                }
                                                else {
                                                  setAlignedEndDate(null);
                                                  setCotermSelectedOptionId(null);
                                                  setCotermSelectedOption(null);
                                                }
                                                // setAlignedEndDateSelection('');
                                                setExpandedRow(null);
                                              }
                                            }}
                                          >
                                            {billingCommitmentTermData &&
                                              billingCommitmentTermData.map((dropdownItem) => (
                                                <option key={dropdownItem.id} value={dropdownItem.id}>
                                                  {dropdownItem.name}
                                                </option>
                                              ))
                                            }
                                          </Form.Select>
                                          {errorTrialState ?
                                            <p className='text-danger'>Trial Plan cannot be choosen in renewal plan</p>
                                            : <p className='empty-paragraph'></p>}
                                        </div>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col><div className='text-muted text-medium mt-1 mb-2'>End Date Alignment</div>
                                        <div className={`ml-3 ${styles['dates-coterm-height']}`}>

                                          {cotermData && (
                                            <Form>
                                              {cotermCommitmentTerm === "monthly" ? (
                                                <>
                                                  {cotermData.monthly?.cotermSubscriptions.map((option, index) => (
                                                    <div key={index}>
                                                      <div className='mr-5'>
                                                        <input
                                                          className='mr-2'
                                                          type="radio"
                                                          name="cotermOption"
                                                          style={{ verticalAlign: 'middle' }}
                                                          checked={cotermSelectedOptionId === index || (alignedEndDate === option.date && !cotermData.alignedMonthEndDateFlag)}
                                                          onClick={() => {
                                                            if (cotermSelectedOptionId === index || alignedEndDate === option.date) {
                                                              setCotermSelectedOptionId(null); // Deselect if already selected
                                                              setAlignedEndDate(null);
                                                              setAlignedEndDateSelection('');
                                                              handleOptionCotermChange(null); // Pass the deselected option
                                                            } else {
                                                              setCotermSelectedOptionId(index);
                                                              setAlignedEndDate(null);
                                                              setAlignedEndDateSelection('dates-selection');
                                                              handleOptionCotermChange(option.date);
                                                            }
                                                          }}
                                                        />
                                                        <span onClick={() => { setCotermChosen(option.date); toggleRow(index) }}>
                                                          <span className='cpointer'>{option.date}</span>
                                                          <span className={`ml-2 gdap-${arrowDirection(index)}-button`} />
                                                        </span>
                                                        {cotermChosen === option.date && expandedRow !== null &&
                                                          <div className={`mt-3`}>
                                                            {<Table responsive="md">
                                                              <thead className='sticky-top'>
                                                                <tr>
                                                                  {ColumnNameCoterm.map(th => {
                                                                    return (<th className={(th === "Commitment Term (Billing Frequency)") ? `${styles['commitment-term-width']}` : "" }>{th}</th>)
                                                                  })}
                                                                </tr>
                                                              </thead>
                                                              <tbody>

                                                                {cotermData && option.subscriptions.map((item, index) => (
                                                                  <tr key={item.card} className={`${styles['vertical-align-middle']}`}>
                                                                    <td >{item.planName}</td>
                                                                    <td >{item.vendorSubscriptionId}</td>
                                                                    <td>{item.billingTerm}</td>
                                                                  </tr>))}

                                                              </tbody>
                                                            </Table>}
                                                          </div>}
                                                      </div>
                                                      <br />
                                                    </div>
                                                  ))}
                                                  {/* Align end date with calendar month */}
                                                  <div className='mb-2'>
                                                    <label className='mr-5'>
                                                      <input
                                                        className='mr-1'
                                                        type="radio"
                                                        name="cotermOption"
                                                        style={{ verticalAlign: 'middle' }}
                                                        checked={cotermSelectedOptionId == "9999" || (alignedEndDate === cotermData.monthly.alignedMonthEndDate && cotermData.monthly.alignedMonthEndDateFlag)}
                                                        onClick={() => {
                                                          if (cotermSelectedOptionId === "9999" || alignedEndDate === cotermData.monthly.alignedMonthEndDate) {
                                                            setAlignedEndDate(null);
                                                            setCotermSelectedOptionId(null); // Deselect if already selected
                                                            setAlignedEndDateSelection('');
                                                            handleOptionCotermChange(null);  // Pass the deselected option
                                                          } else {
                                                            setAlignedEndDate(null);
                                                            setCotermSelectedOptionId("9999");
                                                            setAlignedEndDateSelection('calendar-month-selection');
                                                            handleOptionCotermChange(cotermData.monthly.alignedMonthEndDate)
                                                          }
                                                        }
                                                        }
                                                      /> Align end date with calendar month ({cotermData.monthly.alignedMonthEndDate})
                                                    </label>
                                                  </div>
                                                </>
                                              ) : (
                                                <>
                                                  {cotermData?.yearly.cotermSubscriptions.map((option, index) => (
                                                    <div key={index}>
                                                      <div className='mr-5'>
                                                        <input
                                                          className='mr-2'
                                                          type="radio"
                                                          name="cotermOption"
                                                          style={{ verticalAlign: 'middle' }}
                                                          checked={cotermSelectedOptionId == index || (alignedEndDate === option.date && !cotermData.alignedMonthEndDateFlag)}
                                                          // onChange={() => { setAlignedEndDate(null); setCotermSelectedOptionId(index); setAlignedEndDateSelection('dates-selection'); handleOptionCotermChange(option.date) }}
                                                          onClick={() => {
                                                            if (cotermSelectedOptionId === index || alignedEndDate === option.date) {
                                                              setCotermSelectedOptionId(null); // Deselect if already selected
                                                              setAlignedEndDate(null);
                                                              setAlignedEndDateSelection('');
                                                              handleOptionCotermChange(null); // Pass the deselected option
                                                            } else {
                                                              setCotermSelectedOptionId(index);
                                                              setAlignedEndDate(null);
                                                              setAlignedEndDateSelection('dates-selection');
                                                              handleOptionCotermChange(option.date);
                                                            }
                                                          }}
                                                        />
                                                        <span onClick={() => { setCotermChosen(option.date); toggleRow(index) }}>
                                                          <span className='cpointer'>{option.date}</span>
                                                          <span className={`ml-2 gdap-${arrowDirection(index)}-button`} />
                                                        </span>
                                                        {cotermChosen === option.date && expandedRow !== null &&
                                                          <div className={`mt-3`}>
                                                            {<Table responsive="md">
                                                              <thead className='sticky-top'>
                                                                <tr>
                                                                  {ColumnNameCoterm.map(th => {
                                                                    return (<th className={(th === "Commitment Term (Billing Frequency)") ? `${styles['commitment-term-width']}` : "" }>{th}</th>)
                                                                  })}
                                                                </tr>
                                                              </thead>
                                                              <tbody>

                                                                {cotermData && option.subscriptions.map((item, index) => (
                                                                  <tr key={item.card} className={`${styles['vertical-align-middle']}`}>
                                                                    <td >{item.planName}</td>
                                                                    <td >{item.vendorSubscriptionId}</td>
                                                                    <td>{item.billingTerm}</td>
                                                                  </tr>))}

                                                              </tbody>
                                                            </Table>}
                                                          </div>}
                                                      </div>
                                                      <br />
                                                    </div>
                                                  ))}
                                                  {/* Align end date with calendar month */}
                                                  <div className='mb-2'>
                                                    <label className='mr-5'>
                                                      <input
                                                        className='mr-1'
                                                        type="radio"
                                                        name="cotermOption"
                                                        style={{ verticalAlign: 'middle' }}
                                                        checked={cotermSelectedOptionId == "9999" || (alignedEndDate === cotermData.yearly.alignedMonthEndDate && cotermData.yearly.alignedMonthEndDateFlag)}
                                                        // onChange={() => { setAlignedEndDate(null); setCotermSelectedOptionId("9999"); setAlignedEndDateSelection('calendar-month-selection'); handleOptionCotermChange(cotermData.yearly.alignedMonthEndDate) }}
                                                        onClick={() => {
                                                          if (cotermSelectedOptionId === "9999" || alignedEndDate === cotermData.yearly.alignedMonthEndDate) {
                                                            setAlignedEndDate(null);
                                                            setCotermSelectedOptionId(null); // Deselect if already selected
                                                            setAlignedEndDateSelection('');
                                                            handleOptionCotermChange(null);  // Pass the deselected option
                                                          } else {
                                                            setAlignedEndDate(null);
                                                            setCotermSelectedOptionId("9999");
                                                            setAlignedEndDateSelection('calendar-month-selection');
                                                            handleOptionCotermChange(cotermData.yearly.alignedMonthEndDate)
                                                          }
                                                        }}
                                                      /> Align end date with calendar month ({cotermData.yearly.alignedMonthEndDate})
                                                    </label>
                                                  </div>
                                                </>
                                              )}
                                            </Form>
                                          )}

                                          {
                                            !cotermData &&
                                            <div className={styles["loading-plans"]}><img className="management-console-loading-icon" src={loadingGif} /><p className="text-medium text-strong management-console-loading-text">Loading. . .</p></div>
                                          }
                                        </div>

                                      </Col>
                                    </Row>
                                  </div>
                                  <Row className='mb-4'>
                                    <Col md={6} className='notes-confirmation'>
                                      <div className='ml-6 text-small mb-3 mr-4'><span className='text-extra-strong'>Note :</span> {CspRenewalSettingNote}</div>
                                    </Col>
                                    <Col md={6} className='d-flex align-items-center justify-items-center justify-content-end position-relative'>
                                      {butttonFlag === "" &&
                                        <>
                                          {(dropdownPlanName !== scheduledPlanName || dropdownBillingCommitmentTerm !== scheduledBillingCommitmentTerm || newQuantity !== scheduledQuantity) && !confirmRenewalSettingsLoadingFlag &&
                                            <Button
                                              className="btn btn-lg mx-3 btn-border-light"
                                              variant="light"
                                              onClick={() => {
                                                setQuantityResetFlag(true);
                                                setDropdownBillingCommitmentTerm(scheduledBillingCommitmentTerm);
                                                setDropdownPlanName(scheduledPlanName);
                                                setNewQuantity(scheduledQuantity);
                                              }
                                              }>Clear</Button>}

                                          {(dropdownPlanName !== scheduledPlanName || dropdownBillingCommitmentTerm !== scheduledBillingCommitmentTerm || newQuantity !== scheduledQuantity) && !confirmRenewalSettingsLoadingFlag &&
                                            <Button className='mx-3' onClick={() => CSPPostPlanRenewal()}>Plan Renewal</Button>}

                                          {(dropdownPlanName === scheduledPlanName && dropdownBillingCommitmentTerm === scheduledBillingCommitmentTerm && newQuantity === scheduledQuantity) &&
                                            <Button
                                              className="btn btn-lg mx-3"
                                              variant="light"
                                              disabled
                                            >
                                              Clear
                                            </Button>}
                                          {(dropdownPlanName === scheduledPlanName && dropdownBillingCommitmentTerm === scheduledBillingCommitmentTerm && newQuantity === scheduledQuantity) && !confirmRenewalSettingsLoadingFlag &&
                                            <Button
                                              className="btn btn-lg mx-3"
                                              variant="light"
                                              disabled
                                            >
                                              Plan Renewal
                                            </Button>}
                                          {confirmRenewalSettingsLoadingFlag && subscriptionTableAPIData && <Button
                                            disabled
                                            className="btn btn-lg mx-3"
                                            variant="light"
                                          >
                                            Clear</Button>}
                                          {confirmRenewalSettingsLoadingFlag && subscriptionTableAPIData && <Button
                                            disabled
                                            className="btn btn-lg mx-3"
                                            variant="light"
                                          >
                                            Processing . . .
                                          </Button>}
                                        </>}


                                      {butttonFlag === "edit renewal buttons" &&
                                        <>
                                          {!confirmRenewalSettingsLoadingFlag && <Button className="btn btn-lg mx-2 btn-border-light"
                                            variant="light" onClick={() => {
                                              setShowDropdown("edit not allowed");
                                              setCotermChosen(null);
                                              setCotermSelectedOption(null);
                                              setCotermSelectedOptionId(null);
                                              setExpandedRow(null);
                                              setDropdownBillingCommitmentTerm(scheduledBillingCommitmentTerm);
                                              setDropdownPlanName(scheduledPlanName);
                                              setNewQuantity(scheduledQuantity);
                                              setErrorTrialState(false);
                                              setAlignedEndDate(defaultAlignedEndDate);
                                              setCotermSelectedOption(defaultAlignedEndDate);
                                              setCotermCommitmentTerm(defaultCotermCommitmentTerm);
                                            }}>
                                            Back</Button>}
                                          {
                                            (dropdownPlanName === scheduledPlanName && dropdownBillingCommitmentTerm === scheduledBillingCommitmentTerm && newQuantity === scheduledQuantity && cotermSelectedOption == defaultAlignedEndDate) && !confirmRenewalSettingsLoadingFlag &&
                                            <Button className="btn btn-lg mx-2"
                                              variant="light"
                                              disabled>Save</Button>}
                                          {(dropdownPlanName !== scheduledPlanName || dropdownBillingCommitmentTerm !== scheduledBillingCommitmentTerm || newQuantity !== scheduledQuantity || !(cotermSelectedOption == defaultAlignedEndDate)) && !confirmRenewalSettingsLoadingFlag &&
                                            <Button className="mx-2" onClick={() => { if (!dropdownBillingCommitmentTerm.includes('Trial')) { CSPPostPlanRenewal(); setErrorTrialState(false); } else { setErrorTrialState(true); } }}>Save</Button>}
                                          {confirmRenewalSettingsLoadingFlag && subscriptionTableAPIData && <Button
                                            disabled
                                            className="btn btn-lg mx-2 btn-border-light"
                                            variant="light"
                                          >
                                            Back
                                          </Button>}
                                          {confirmRenewalSettingsLoadingFlag && subscriptionTableAPIData && <Button
                                            disabled
                                            className="ml-3 btn-border-light w-md-auto col-xs-12 mr-1"
                                            variant="light"
                                          >
                                            Processing . . .
                                          </Button>}
                                        </>}
                                    </Col>
                                  </Row>
                                </div>}
                            </Card>}

                          {/* when editing is not allowed in manage renewal*/}
                          {showDropdown === "edit not allowed" &&
                            <Card className={`${styles['renewal-settings-card']}`}>
                              <div className='ml-6 mt-3 mr-3 mb-15 '>
                                <Row className='mb-3'>
                                  <Col md={6}><p className='text-muted'>Scheduled Plan</p>
                                    <p className='text-wrap '
                                      style={{ wordBreak: 'none' }}
                                    >{!(scheduledPlanName === null) ? scheduledPlanName : subscriptionTableAPIData.planName}
                                    </p>
                                  </Col>
                                  <Col md={3}><p className='text-muted'>Quantity</p>
                                    <p>{!(scheduledQuantity === null) ? scheduledQuantity : subscriptionTableAPIData.quantity}</p>
                                  </Col>
                                  <Col md={3} className='text-right'>
                                    {!confirmRenewalSettingsLoadingFlag && subscriptionTableAPIData && <Button className='pl-4 mx-3' onClick={() => setConfirmRenewalSettingsDeleteFlag(true)}>Delete Renewal</Button>}
                                    {confirmRenewalSettingsLoadingFlag && subscriptionTableAPIData && <Button
                                      disabled
                                      className="pl-4 mx-3"
                                      variant="light"
                                    >
                                      Processing . . .
                                    </Button>}
                                  </Col>
                                </Row>
                                <Row>
                                  <Col><p className='text-muted'>Commitment Term (Billing Frequency):</p>
                                    <p>{!(scheduledBillingCommitmentTerm === null) ? scheduledBillingCommitmentTerm : subscriptionTableAPIData.billingTerm}</p>
                                  </Col>
                                </Row>
                                <Row className='mt-4'>
                                  <div className={`${styles['table-coterm-height']}`}>
                                    {alignedEndDate && alignedEndDate != null && alignedEndDate != "" && <Col><p className='text-muted'>Aligned End Date:</p>
                                      <p>{alignedEndDate}</p>
                                    </Col>
                                    }
                                  </div>
                                </Row>
                              </div>
                              <Row className='mb-1 mr-1 py-2'>
                                <Col md={8} className='notes-confirmation'>
                                  <div className='ml-6 text-small mb-3 mr-4'><span className='text-extra-strong'>Note :</span> {CspRenewalSettingNote}</div>
                                </Col>
                                <Col md={4} className='text-right'>
                                  {!deleteInitiated && <Button className='pl-4 mx-3 px-3' onClick={() => { setButtonFlag("edit renewal buttons"); setShowDropdown("edit allowed"); }}>Edit Renewal</Button>}
                                  {deleteInitiated && <Button disabled className="btn btn-lg mx-3 px-3 pl-4" variant="light">Edit Renewal</Button>}
                                </Col>
                              </Row>
                            </Card>
                          }
                        </Col>}
                      <Col md={8}>
                        {(hasCotermApiError || hasManageApiError) &&
                          <div className={styles["no-data-cloud-invoice"]}>
                            <img src={EmptyDataTableFilterSearch} className="empty-cloud-invoice-table-svg" />
                            <h2 className="mt-4 mb-3">{GenericServerError}</h2>
                          </div>
                        }
                        {serverError && !hasCotermApiError && !hasManageApiError &&
                          <div className={styles["no-data-cloud-invoice"]}>
                            <img src={EmptyDataTableFilterSearch} className="empty-cloud-invoice-table-svg" />
                            <h2 className="mt-4 mb-3">{GenericServerError}</h2>
                          </div>
                        }
                         {emptyDataError &&
                          <div className={styles["no-data-cloud-invoice"]}>
                            <img src={EmptyDataTableFilterSearch} className="empty-cloud-invoice-table-svg" />
                            <h2 className="mt-4 mb-3">{GenericEmptyStateError}</h2>
                          </div>
                         } </Col>
                    </Row>}

                  <div className={`mt-4 ${styles['gws-downgrade-core']}`}>
                    {/* loading state while table is not loaded */}
                    {!subscriptionTableAPIData &&
                      <div className={styles["loading-plans"]}><img className="management-console-loading-icon" src={loadingGif} /><p className="text-medium text-strong management-console-loading-text">Loading. . .</p></div>
                    }
                    {/* cancel renewal subscription when auto renew is true and is nce plan*/}
                    {(subscriptionTableAPIData && subscriptionTableAPIData.autoRenew && isNcePlan && selectedOption === 'cancel') &&
                      <Table className={`${styles['csp-change-status-core-table']}`} responsive="md">
                        <thead className="sticky-top">
                          <tr>
                            {ColumnNameCancel.map(th => {
                              return (<th className={(th === "Reseller Price (Ex GST)" || th === "Quantity") ? "text-right" : ""}>{th}</th>)
                            })}
                          </tr>
                        </thead>
                        <tbody>
                          {
                            <tr>
                              <td>{subscriptionTableAPIData.planName}</td>
                              <td>{subscriptionTableAPIData.billingTerm}</td>
                              <td className='text-right'>${subscriptionTableAPIData.price}</td>
                              <td className='text-right'>{subscriptionTableAPIData.quantity}</td>
                              <td>{"Yes"}</td>
                            </tr>
                          }
                        </tbody>
                      </Table>
                    }

                    {/* cancel renwal subscription when auto renew is true and is legacy plan*/}
                    {(subscriptionTableAPIData && subscriptionTableAPIData.autoRenew && !isNcePlan) &&
                      <Table className={`${styles['csp-change-status-core-table']}`} responsive="md">
                        <thead className="sticky-top">
                          <tr>
                            {ColumnNameCancel.map(th => {
                              return (<th className={(th === "Reseller Price (Ex GST)" || th === "Quantity") ? "text-right" : ""}>{th}</th>)
                            })}
                          </tr>
                        </thead>
                        <tbody>
                          {
                            <tr>
                              <td>{subscriptionTableAPIData.planName}</td>
                              <td>{subscriptionTableAPIData.billingTerm}</td>
                              <td className='text-right'>${subscriptionTableAPIData.price}</td>
                              <td className='text-right'>{subscriptionTableAPIData.quantity}</td>
                              <td>{"Yes"}</td>
                            </tr>
                          }
                        </tbody>
                      </Table>
                    }

                    {/* start auto renew when auto renew is false*/}
                    {subscriptionTableAPIData && !subscriptionTableAPIData.autoRenew &&
                      <Table className={`${styles['csp-change-status-core-table']}`} responsive="md">
                        <thead className="sticky-top">
                          <tr>
                            {ColumnNameCancel.map(th => {
                              return (<th className={(th === "Reseller Price (Ex GST)" || th === "Quantity") ? "text-right" : ""}>{th}</th>)
                            })}
                          </tr>
                        </thead>
                        <tbody>
                          {
                            <tr>
                              <td>{subscriptionTableAPIData.planName}</td>
                              <td>{subscriptionTableAPIData.billingTerm}</td>
                              <td className='text-right'>${subscriptionTableAPIData.price}</td>
                              <td className='text-right'>{subscriptionTableAPIData.quantity}</td>
                              <td>{"No"}</td>
                            </tr>
                          }
                        </tbody>
                      </Table>
                    }

                    {/* for autorenew true and cancel for legacy plan */}
                    {(subscriptionTableAPIData && subscriptionTableAPIData.autoRenew && !isNcePlan) &&
                      <Row>
                        <Col md={9} className='notes-confirmation'> <p className='text-small ml-12'>
                          {/* <span className='text-extra-strong'>Note :</span> Add Note */}
                        </p></Col>
                        <Col md={3} className='text-right'>
                          {!confirmRenewalSettingsLoadingFlag && <Button
                            onClick={() => setConfirmRenewalSettingsCancelFlag(true)}
                          > Turn Off Auto Renewal
                          </Button>}
                          {confirmRenewalSettingsLoadingFlag && subscriptionTableAPIData && <Button
                            disabled
                            className="ml-3 btn-border-light w-md-auto col-xs-12"
                            variant="light"
                          >
                            Processing . . .
                          </Button>}

                        </Col>
                      </Row>
                    }

                    {/* for autorenew true and tab cancel for isnce plan */}
                    {(subscriptionTableAPIData && subscriptionTableAPIData.autoRenew && selectedOption === 'cancel') &&
                      <Row>
                        <Col md={9} className='notes-confirmation'> <p className='text-small ml-12'>
                          {/* <span className='text-extra-strong'>Note :</span> Add Note */}
                        </p></Col>
                        <Col md={3} className='text-right'>
                          {!confirmRenewalSettingsLoadingFlag && <Button
                            onClick={() => setConfirmRenewalSettingsCancelFlag(true)}
                          > Turn Off Auto Renewal
                          </Button>}
                          {confirmRenewalSettingsLoadingFlag && subscriptionTableAPIData && <Button
                            disabled
                            className="ml-3 btn-border-light w-md-auto col-xs-12"
                            variant="light"
                          >
                            Processing . . .
                          </Button>}
                        </Col>
                      </Row>
                    }

                    {/* for auto renew false both in nce plan and legacy plan */}
                    {(subscriptionTableAPIData && !subscriptionTableAPIData.autoRenew) &&
                      <Row>
                        <Col md={8} className='notes-confirmation'>
                          <p className='text-small ml-12'>
                            {/* <span className='text-extra-strong'>Note :</span> Add Note */}
                          </p>
                        </Col>
                        <Col md={4} className='text-right'>
                          {subscriptionTableAPIData && !subscriptionTableAPIData.autoRenew && !confirmRenewalSettingsLoadingFlag && <Button
                            onClick={() => setConfirmRenewalSettingsCancelFlag(true)}
                          >
                            Start Auto Renew
                          </Button>}
                          {confirmRenewalSettingsLoadingFlag && subscriptionTableAPIData && <Button
                            disabled
                            className="ml-3 btn-border-light w-md-auto col-xs-12"
                            variant="light"
                          >
                            Processing . . .
                          </Button>}
                        </Col>
                      </Row>
                    }
                  </div>
                </div>
              </Container>
            </>
          </div>
        )}
      </Popup>

    </div>
  )
}

export default CSPRenewalSettingsPage